<div id="main-container">
  <div id="header">
    <h2>Select to Navigate</h2>
  </div>
  <div id="markers">
    <app-measure-marker-item
      *ngFor="let marker of markers"
      [marker]="marker"
      [selected]="selectedMarker === marker"
      [current]="currentMarker === marker"
      (click)="markerEmitter.emit(marker)"
    ></app-measure-marker-item>
  </div>
</div>
