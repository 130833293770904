<mat-card class="mat-elevation-z0">
	<mat-card-header>
		<mat-card-title>
			{{title}}
		</mat-card-title>
		<button
				mat-flat-button
				style='color: #FFFFFF; background-color: #ee3041;'
				*ngIf="group != null"
				routerLink="/manage/{{group.id}}/products"
				routerLinkActive="active"
		>
			<mat-icon>add_circle</mat-icon>
			Add Product
		</button>
	</mat-card-header>
	<div class="card-content">
		<div
				*ngIf="group == null"
				style="width: 95%; display:flex; justify-content: start; margin: 20px;"
		>
			Select or create a group above
		</div>

		<mat-table
				*ngIf="products"
				[dataSource]='products'
				class='mat-elevation-z0'
		>
			<ng-container matColumnDef='name'>
				<mat-header-cell *matHeaderCellDef>Product</mat-header-cell>
				<mat-cell *matCellDef='let product'>
					<div *ngIf="!isProductPack(product)">
						<span class="product-title">{{product.title}}</span>
					</div>
					<div
							*ngIf="isProductPack(product)"
							class="all-access-product"
					>
						<span class="product-title">{{product.title}}</span>
						<span class="product-number">
							{{product.groupProductItems.length || 0}} of {{product.productItems}} Songs Selected
						</span>
						<div class="product-item">
							<div *ngFor="let song of product.groupProductItems">
								{{song.title}}
								<span> -  <a (click)="removeSong(product, song)">Remove</a></span>
							</div>
						</div>
					</div>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef='action'>
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell
						*matCellDef='let product'
				>
					<div align="right">
						<a
								*ngIf="isProductPack(product)"
								class="add-songs"
								(click)="addSongs(product)"
						>Select Songs</a>
						<a (click)="removeProduct(product)">Remove Product</a>
					</div>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef='displayedColumns'></mat-header-row>
			<mat-row *matRowDef='let row; columns: displayedColumns;'></mat-row>
		</mat-table>
	</div>
</mat-card>
