<breadcrumb></breadcrumb>

<div>
	<mat-card class="mat-elevation-z0">
		<mat-card-header>
			<mat-card-title *ngIf="group && productPack">
				Select songs from {{productPack.title}} to add to {{group.name}}
			</mat-card-title>
		</mat-card-header>

		<div class="card-content">
			<div id="top-row">
				<mat-form-field appearance="standard">
					<mat-label>Filter</mat-label>
					<input
							matInput
							placeholder="Filter"
							(keyup)="applyFilter($event)"
							#input
					>
				</mat-form-field>
				<button
						mat-raised-button
						(click)="cancelProducts()"
				>Cancel</button>
				<button
						mat-raised-button
						color="primary"
						(click)="saveProducts()"
				>Save</button>
			</div>

			<table mat-table [dataSource]="products">

				<ng-container matColumnDef="select">
					<th mat-header-cell *matHeaderCellDef>
						<mat-checkbox
								color="primary"
								(change)="$event ? masterToggle() : null"
								[checked]="selection.hasValue() && isAllSelected()"
								[indeterminate]="selection.hasValue() && !isAllSelected()"
								[aria-label]="checkboxLabel()"
						></mat-checkbox>
					</th>
					<td mat-cell *matCellDef="let row">
						<mat-checkbox
								color="primary"
								(click)="$event.stopPropagation()"
								(change)="$event ? selection.toggle(row) : null"
								[checked]="selection.isSelected(row)"
								[aria-label]="checkboxLabel(row)"
						></mat-checkbox>
					</td>
				</ng-container>

				<ng-container matColumnDef="title">
					<th mat-header-cell *matHeaderCellDef> Title </th>
					<td mat-cell *matCellDef="let element">
						<span>
							<b>{{element.title}}</b>
							<div *ngIf="element.variants">
								<div
										*ngFor="let v of element.variants"
										class="variant variant-title"
								>{{v.title}}</div>
							</div>
						</span>
					</td>
				</ng-container>

				<ng-container matColumnDef="sku">
					<th mat-header-cell *matHeaderCellDef> SKU </th>
					<td mat-cell *matCellDef="let element">
						<span class="sku-col">
							<b>&nbsp;</b>
							<div *ngIf="element.variants">
								<div
										*ngFor="let v of element.variants"
										class="variant"
								>
									{{v.sku}}
								</div>
							</div>
						</span>
					</td>
				</ng-container>

				<ng-container matColumnDef="genre">
					<th mat-header-cell *matHeaderCellDef> Genre </th>
					<td mat-cell *matCellDef="let element"> {{element.genre}} </td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell" colspan="5">No data matching the filter "{{input.value}}"</td>
				</tr>
			</table>
		</div>
	</mat-card>
</div>
