import { Component, OnInit } from '@angular/core';
import { Product } from '../models/Product';
import { UntypedFormControl } from '@angular/forms';
import { FirestoreService } from '../services/firestore.service';
import { StateService } from '../services/state.service';
import { Customer } from '../models/Customer';
import { ProductPack } from '../models/ProductPack';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router } from '@angular/router';
import { Group } from '../models/Group';
import { forkJoin, Observable } from 'rxjs';


@Component({
  selector: 'product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

	public group!: Group;
	public search = new UntypedFormControl();
	public products = new MatTableDataSource<Product>();
	public selection = new SelectionModel<Product>(true, [])
	public displayedColumns: string[] = [
		'select',
		'title',
		'assigned',
		'available',
		'expiration'
	];
	public customer!: Customer;

  constructor(
		public firestoreService: FirestoreService,
		public stateService: StateService,
		private router: Router,
		private route: ActivatedRoute,
	) { }

	ngOnInit() {
		this.getGroup();
		this.stateService.getCustomerObservable().subscribe((customer: Customer | null) => {
			if (customer) {
				this.customer = customer;
				this.getProducts();
			} else {
				this.router.navigate(['/manage']);
			}
		});
	}

	private getProducts() {
		this.firestoreService.getProducts(this.customer.id)
			.subscribe((products: (Product | ProductPack)[]) => {
				this.products.data = products;
				this.initializeSelection();
			});
	}

	private getGroup() {
		this.firestoreService.getGroup(this.route.snapshot.paramMap.get('groupId'))
			.subscribe((group: Group | null) => {
				if (!group) {
					console.error('Group does not exist. Redirecting to manage page');
					this.router.navigate(['/manage']);
					return;
				}
			this.group = group;
				this.stateService.breadcrumbs.next([{
					name: `Home`,
					route: `/dashboard`,
				},{
					name: `Edit: ${this.group.name}`,
					route: `/manage/${this.group.id}`
				},{
					name: `Add Product`,
					route: `${this.group.id}/products`
				}]);
			});
	}

	private initializeSelection() {
		for(let product of this.products.data) {
			if (product.id && this.group.productIds.indexOf(product.id) !== -1) {
				this.selection.select(product);
			}
		}
	}

	public cancelProducts() {
		this.router.navigate([`/manage/${this.group.id}`])
	}

	public saveProducts() {
		const forkArr: Observable<Product>[] = [];
		for (let product of this.selection.selected) {
			if (this.group.productIds.indexOf(product.id) === -1) {
				if (!product.assigned)
					product.assigned = 0;

				if (this.group.musicians && product.available - this.group.musicians.length < 0) {
					window.alert('Cannot add product because the number of musicians exceed the available amount of products');
				}	else {
					product.assigned = product.assigned + this.group.musicians.length;
					forkArr.push(this.firestoreService.saveProduct(product, this.customer.id));
				}
			} else {
				forkArr.push(this.firestoreService.saveProduct(product, this.customer.id));
			}
		}

		forkJoin(forkArr).subscribe((products: Product[]) => {
			this.group.productIds = products.map(prod => prod.id);
			this.firestoreService.saveGroup(this.group, this.customer.id)
				.subscribe((group: Group) => {
					this.group = group;
					this.router.navigate([`/manage/${this.group.id}`])
				});
		});
	}

	applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.products.filter = filterValue.trim().toLowerCase();
  }

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.products.data.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if(this.isAllSelected()) {
			this.selection.clear();
			return;
		}
		this.selection.select(...this.products.data);
	}

	checkboxLabel(row?: Product): string {
		if (!row) {
			return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
	}
}
