<mat-card class='mat-elevation-z0'>
	<mat-card-header>
		<mat-card-title>
			All Products
		</mat-card-title>
	</mat-card-header>
	<div class='card-content'>
		<mat-table [dataSource]='products' class='mat-elevation-z0'>
			<ng-container matColumnDef='name'>
				<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
					<mat-cell *matCellDef='let product'>
						<span>
							{{product.id}} - {{product.title}}
							<div *ngIf="product.variants">
								<div
										*ngFor="let v of product.variants"
										class="variant-title"
								>
									{{v.title}}
								</div>
							</div>
						</span>
					</mat-cell>
			</ng-container>

			<ng-container matColumnDef='sku'>
				<mat-header-cell *matHeaderCellDef>SKU</mat-header-cell>
					<mat-cell *matCellDef='let product'>
						<span class="product-col">
							{{product.sku}}
							<div *ngIf="product.variants">
								<div *ngFor="let v of product.variants">
									{{v.sku}}
								</div>
							</div>
						</span>
					</mat-cell>
			</ng-container>

			<ng-container matColumnDef='genre'>
				<mat-header-cell *matHeaderCellDef>Genre</mat-header-cell>
					<mat-cell *matCellDef='let product'>
						<span class="product-col">
							{{product.genre}}
							<div *ngIf="product.variants">
								<div *ngFor="let v of product.variants">
									{{v.genre}}
								</div>
							</div>
						</span>
					</mat-cell>
			</ng-container>

			<ng-container matColumnDef='type'>
				<mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
					<mat-cell *matCellDef='let product'>
						<span class="product-col">
							{{product.type}}
							<div *ngIf="product.variants">
								<div *ngFor="let v of product.variants">
									{{v.type}}
								</div>
							</div>
						</span>
					</mat-cell>
			</ng-container>
			<mat-header-row *matHeaderRowDef='displayedColumns'></mat-header-row>
			<mat-row *matRowDef='let row; columns: displayedColumns;'></mat-row>
		</mat-table>
	</div>
</mat-card>
