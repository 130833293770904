import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { Customer } from '../models/Customer';
import { FirestoreService } from './firestore.service';
import { FunctionService } from './functions.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Breadcrumb } from '../models/Breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class StateService {
	public user: User;
	public customer: Customer;
	public previousRouteInfo:string;
	public breadcrumbs: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);
	public modeLogin: String = "Student";

  constructor(
		public firestoreService: FirestoreService,
		public functionsService: FunctionService,
		private router: Router,
	) { }

	public updateModeLogin(newMode: String) {
		this.modeLogin = newMode;
		console.log(newMode)
	}

	public getCustomerObservable(): Observable<Customer | null> {
		if (this.customer.id) {
			return of(this.customer);
		}
		return this.firestoreService.getCustomer(this.user.email).pipe(
			map((customer: Customer | null) => {
				this.customer = customer;
				return this.customer;
			}),
		);
	}

	public getUser(): User | null {
		if (this.user) {
			return this.user;
		}
		else {
			const user: User | null = JSON.parse(localStorage.getItem('accompany-user'));
			if (user === null) {
				return null;
			}
			this.setUser(user);
			return user;
		}
	}

	public setUser(user: User) {
		this.user = user;
		this.customer = new Customer();
		this.customer.email = this.user.email;
		this.customer.name = this.user.name;

		this.firestoreService.getCustomer(this.user.email)
			.subscribe((customer: Customer | null) => {
				if (!customer) {
					this.signOut();
					throw 'Could not retrieve customer to set the user';
				}
				this.customer = customer;
				this.user.email = this.customer.email;
				this.user.name = this.customer.name;
				localStorage.setItem('accompany-user', JSON.stringify(this.user));
			});

		this.functionsService.isAdminCheck(this.user.email).subscribe(
			(isAdmin: boolean) => {
				this.user.is_admin = isAdmin;
				localStorage.setItem('accompany-user', JSON.stringify(this.user));
			}
		);

		localStorage.setItem('accompany-user', JSON.stringify(this.user));
	}

	public signOut() {
		this.user = undefined;
		localStorage.setItem('accompany-user', null);
		this.customer = undefined;
		this.router.navigate(['']);
	}
}
