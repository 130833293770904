import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from '../models/Customer';
import { FirestoreService } from '../services/firestore.service';
import { FunctionService } from '../services/functions.service';
import { StateService } from '../services/state.service';


@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	displayedColumns: string[] = ['name', 'email', 'action'];
	public customers: Customer[] = [];
	public reloadingPurchases: boolean = false;

	constructor(
		private firestoreService: FirestoreService,
		private functionService: FunctionService,
		private stateService: StateService,
		private router: Router,
	) {
		if (!this.stateService.getUser().is_admin) {
			this.router.navigate(['/manage']);
		}
	}

	ngOnInit() {
		this.stateService.breadcrumbs.next([]);
		this.getCustomers();
	}

	public reloadPurchases(customer: Customer): void {
		this.reloadingPurchases = true;
		console.log(`Reload purchases for ${customer.name}`);
		this.functionService.getRefreshOrders(customer.id).subscribe((result: boolean) => {
			this.reloadingPurchases = false;
			window.alert(`Refresh ${result ? 'succeded!' : 'failed'}`); // TODO: Replace
		});
	}

	public mockCustomer(customer: Customer) {
		console.log(`Mocking ${customer.name}`);
		this.stateService.customer = customer;
	}

	public getCustomers(): void {
		this.firestoreService.getCustomers().subscribe((customers: Customer[]) => {
			this.customers = customers;
		});
	}
}
