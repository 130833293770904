<div fxLayout="column" fxFlexFill>

	<div class="select-login" *ngIf="stateService.modeLogin =='Select'">
		<button mat-flat-button class="login-button" (click)="stateService.modeLogin = 'Director'">Director Login</button>
		<button mat-flat-button class="login-button" (click)="stateService.modeLogin = 'Student'">Login</button>
	</div>

	<div class="login-form" *ngIf="stateService.modeLogin =='Director'">
		<form [formGroup]="directorForm" (ngSubmit)="validateDirector()">
			<h1 class="title">Director Login</h1>
			<div class="form-body">
				<h2>If this is your first time accessing the Admin Portal, please create a new account using your A-ccompany store email and a password.</h2>
				<div class="input-row">
					<input type="email" formControlName="email" class="form-control" placeholder="Email" required autofocus>
					<input type="password" formControlName="password" class="form-control" placeholder="Password" required>
					<div class="submit-row">
						<button mat-flat-button class="submit-button" type="submit">Sign in</button>
						<div style="display: flex; flex-direction: column;">
							<div><b>New Customer? </b> <a (click)="stateService.modeLogin = 'Create'">Create Account</a></div>
							<a (click)="stateService.modeLogin = 'Password'">Forgot your password?</a>
						</div>
					</div>
				</div>
				<br>
				<p (click)="stateService.modeLogin = 'Student'" style="font-style: italic; color: #ee3041; cursor: pointer;">Student? Log in here.</p>
			</div>
		</form>
	</div>

	<div class="login-form" *ngIf="stateService.modeLogin == 'Student'">
		<form [formGroup]="studentForm" (ngSubmit)="validateStudent()">
			<h1 class="title">Log in</h1>
			<div class="form-body">
				<h2>Email Address OR Redemption Code</h2>
				<div class="input-row">
					<input formControlName="student_code" class="form-control" placeholder="Email or Code" required autofocus>
					<div class="submit-row">
						<button mat-flat-button class="submit-button" type="submit">Sign in</button>
					</div>
				</div>
				<br>
				<p (click)="stateService.updateModeLogin('Director')" style="font-style: italic; color: #ee3041; cursor: pointer;">Director? Log in here.</p>
			</div>
		</form>
	</div>

	<div class="login-form" *ngIf="stateService.modeLogin == 'Create'">
		<form [formGroup]="newAccountForm" (ngSubmit)="validateNewAccount()">
			<h1 class="title">Create Account</h1>
			<div class="form-body">
				<h2>If this is your first time accessing the Admin Portal, please create a new account using your A-ccompany store email and a password.</h2>
				<div class="input-row">
					<input type="email" formControlName="email" class="form-control" placeholder="Email" required autofocus>
					<input type="password" formControlName="password" class="form-control" placeholder="Password" required>
					<input type="password" formControlName="confirmPassword" class="form-control" placeholder="Confirm Password" required>
					<div class="submit-row">
						<button mat-flat-button class="submit-button" type="submit">Create</button>
						<b>Returning Customer?</b> <a (click)="stateService.modeLogin= 'Director'">Sign In</a>
					</div>
				</div>
			</div>
		</form>
	</div>

	<div class="login-form" *ngIf="stateService.modeLogin =='Password'">
		<form [formGroup]="passwordForm" (ngSubmit)="forgotPassword()">
			<h1 class="title">Forgot Password</h1>
			<div class="form-body">
				<h2>If this is your first time accessing the Admin Portal, please create a new account using your A-ccompany store email and a password.</h2>
				<div class="input-row">
					<h3 class="small-title">Reset your password</h3>
					<p>We will send you an email to reset your password.</p>
					<input
							type="email"
							formControlName="email"
							class="form-control"
							placeholder="Email"
							required
							autofocus
					>
					<div class="submit-row">
						<button
								mat-flat-button
								class="submit-button"
								type="submit"
						>Submit</button>
						<button mat-flat-button class="submit-button" (click)="stateService.modeLogin ='Director'">Cancel</button>
					</div>
				</div>
			</div>
		</form>
	</div>
	
	<reset-password
			*ngIf="stateService.modeLogin === 'New Password'"
			class="login-form"
	></reset-password>

	<confirm-email
			*ngIf="stateService.modeLogin === 'Verify'"
			class="login-form"
	></confirm-email>
</div>
