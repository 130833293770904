import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FunctionService } from '../services/functions.service';
import { StateService } from '../services/state.service';
import { StudentService } from '../services/student.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	// public mode:(
	// 		'Select'
	// 	| 'Director'
	// 	| 'Password'
	// 	| 'Create'
	// 	| 'Student'
	// 	| 'New Password'
	// 	| 'Verify'
	// ) = 'Student';
	public formGroup;
	public formErrors = false;
	public loginFailed = false;

	constructor(
		public stateService: StateService,
		studentService: StudentService,
		private authService: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		public fb: UntypedFormBuilder,
		private functionService: FunctionService,
	) {
		if (stateService.getUser()) {
			this.router.navigate(['/dashboard']);
		} else if (studentService.getStudent()) {
			this.router.navigate(['/player']);
		}
	}

	ngOnInit() {
		this.route.queryParams.subscribe((params: any) => {
			if (params.mode === 'resetPassword') {
				// this.mode = 'New Password';
				this.stateService.updateModeLogin('New Password')
			} else if (params.mode === 'verifyEmail') {
				// this.mode = 'Verify';
				this.stateService.updateModeLogin('Verify')
			} else {
				// this.mode = 'Student';
				this.stateService.updateModeLogin('Student')
			}
		});
	}

	public directorForm = this.fb.group({
		email: ["", Validators.required],
		password: ["", Validators.required],
	});

	public newAccountForm = this.fb.group({
		email: ["", Validators.required],
		password: ["", Validators.required],
		confirmPassword: ["", Validators.required],
	});

	public passwordForm = this.fb.group({
		email: ["", Validators.required],
		password: ["", Validators.required],
		confirmPassword: ["", Validators.required],
	});

	public studentForm = this.fb.group({
		student_code: ["", Validators.required],
	});

	public validateDirector() {
		let form = this.directorForm.value;
		let email = form.email;
		let password = form.password;
		if ( !(email && password)) {
			this.formErrors = true;
			return;
		}

		this.loginFailed = false;
		this.formErrors = false;
		this.loginDirector(email, password);
	}

	private loginDirector(email:string, password:string): void {
		console.log("call firebase and log in director");
		this.authService.signIn(email, password).then(_ => {
			this.router.navigate(['dashboard'])
		});
	}

	public validateStudent() {
		let form = this.studentForm.value;
		let student_code = form.student_code;
		if (!student_code) {
			this.formErrors = true;
			return;
		}

		this.loginFailed = false;
		this.formErrors = false;
		this.authService.studentSignIn(student_code);
	}

	public validateNewAccount() {
		let form = this.newAccountForm.value;
		let email = form.email;
		let password = form.password;
		let confirmPassword = form.confirmPassword;
		if (!(email && password && confirmPassword)) {
			this.formErrors = true;
			return;
		}

		this.loginFailed = false;
		this.formErrors = false;
		this.authService.signUp(email, password);
	}

	public forgotPassword() {
		const form = this.passwordForm.value;
		const email = form.email;
		if (!email) {
			this.formErrors = true;
			return;
		}

		this.loginFailed = false;
		this.formErrors = false;
		this.authService.forgotPassword(email);
		// this.mode = 'Student';
		this.stateService.updateModeLogin('Student');
		
		
	}
}
