import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '../models/Customer';
import { Group } from '../models/Group';
import { Musician } from '../models/Musician';
import { Product } from '../models/Product';
import { FirestoreService } from '../services/firestore.service';
import { StateService } from '../services/state.service';


@Component({
	selector: 'manage',
	templateUrl: './manage.component.html',
	styleUrls: ['./manage.component.scss']
})

export class ManageComponent implements OnInit {
	public groups?: Group[];
	public customer!: Customer;
	public group: Group;

	constructor(
		public firestoreService: FirestoreService,
		public stateService: StateService,
		private router: Router,
		private route: ActivatedRoute,
	) { }

	ngOnInit() {
		this.stateService.breadcrumbs.next([{name: 'home', route: '/dashboard'}]);
		this.getGroup();
		this.stateService.getCustomerObservable().subscribe((customer: Customer | null) => {
			if (customer) {
				this.getGroups(customer.id);
				this.customer = customer;
			} else {
				this.router.navigate(['dashboard']);
				throw 'A customer must be selected to access the manage page';
			}
		});
	}

	public editGroup(group: Group) {
		this.group = group;
		if (this.group.id) {
			this.router.navigate([`manage/${group.id}`]);
		}
	}

	public getGroups(customerId: number): void {
		this.firestoreService.getGroups(customerId).subscribe((groups: Group[]) => {
			this.groups = groups;
		});
	}

	private getGroup() {
		if (this.route.snapshot.paramMap.get('groupId')) {
			this.firestoreService.getGroup(this.route.snapshot.paramMap.get('groupId'))
				.subscribe((group: Group | null) => {
					if (!group)
						return;
					this.group = group;
					this.stateService.breadcrumbs.next([{
						name: `Home`,
						route: `/dashboard`,
					},{
						name: `Edit: ${group.name}`,
						route: `/manage/${this.group.id}`
					}]);
				});
		}
	}

	public setGroup(group: Group) {
		this.router.navigate([`manage/${group.id}`]);
	}
}
