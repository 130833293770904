<mat-card class='mat-elevation-z0'>
	<mat-card-header>
		<mat-card-title>
			{{title}}
		</mat-card-title>
		<button
				mat-flat-button
				style="color: #FFFFFF; background-color: #ee3041;"
				(click)="addGroup()"
				*ngIf="group == null"
		><mat-icon>add_circle</mat-icon>Add Group</button>
	</mat-card-header>
	<div class='card-content'>
		<mat-table [dataSource]='groups' class='mat-elevation-z0' *ngIf='group == null'>
			<ng-container matColumnDef='name'>
				<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
				<mat-cell *matCellDef='let group'>
					<a (click)='editGroup(group)'>{{group.name}}</a>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef='products'>
				<mat-header-cell *matHeaderCellDef>Products</mat-header-cell>
				<mat-cell *matCellDef='let group'>
					{{group.productIds.length}}
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef='musicians'>
				<mat-header-cell *matHeaderCellDef>Musicians</mat-header-cell>
				<mat-cell *matCellDef='let group'>
					{{group.musicians.length}}
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef='action'>
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef='let group'>
					<a (click)="deleteGroup(group)">Delete</a>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef='displayedColumns'></mat-header-row>
			<mat-row *matRowDef='let row; columns: displayedColumns;'></mat-row>
		</mat-table>

		<div *ngIf='group != null && !new' style="width: 100%; padding: 10px;">
			<div
					style="display: flex; flex-direction: row; justify-content: space-between;"
			>
				<div style="display: flex; flex-direction: column;">
					<mat-label>Group Name:</mat-label>
					<input [formControl]='name' class='form-control' required>
				</div>
				<div style="display: flex; flex-direction: column;">
					<mat-label>Preferred Genres/Collections (Optional)</mat-label>
					<mat-select
							multiple
							class="form-control"
							placeholder="--All (Default)--"
							[formControl]="groupGenres"
					>
						<mat-select-trigger>
							{{groupGenres.value && groupGenres.value.length > 0? groupGenres.value.join(", ") : '--All (Default)--'}}
						</mat-select-trigger>
						<mat-option
								*ngFor="let genre of genres"
								class="mat-primary"
								[value]="genre"
						>
							{{genre}}
						</mat-option>
					</mat-select>
				</div>
				<div style="display: flex; flex-direction: row; align-items: center;">
					<button mat-flat-button style="background-color: #dddddd; margin-right: .5rem;" (click)="deleteGroup(group)">Delete</button>
					<button mat-flat-button style="background-color: #ee3041; color: #FFFFFF;" (click)="saveFormGroup()">Save</button>
				</div>
			</div>
		</div>

		<div
				*ngIf="group != null && new"
				style="width: 100%; padding: 10px;"
		>
			<div style="display: flex; flex-direction: row; justify-content: space-between;">
				<div style="display: flex; flex-direction: column;">
					<p>{{error}}</p>
					<mat-label>Group Name:</mat-label>
					<input
							[formControl]="name"
							class="form-control"
							required
					>
				</div>
				<div style="display: flex; flex-direction: column;">
					<mat-label>Preferred Genres/Collections (Optional)</mat-label>
					<mat-select
							multiple
							class="form-control"
							placeholder="--All (Default)--"
							[formControl]="groupGenres"
					>
						<mat-select-trigger>
							{{groupGenres.value && groupGenres.value.length > 0? groupGenres.value.join(", ") : '--All (Default)--'}}
						</mat-select-trigger>
						<mat-option
								*ngFor="let genre of genres"
								[value]="genre"
								class="mat-primary"
						>{{genre}}</mat-option>
					</mat-select>
				</div>
				<div style="display: flex; flex-direction: row; align-items: center;">
					<button
							mat-flat-button
							style="background-color: #dddddd; margin-right: .5rem;"
							(click)="cancelNewGroup()"
					>Cancel</button>
					<button
							mat-flat-button
							style="background-color: #ee3041; color: #FFFFFF;"
							(click)="saveFormGroup()"
					>Save</button>
				</div>
			</div>
		</div>
	</div>
</mat-card>
