<div class="manage-header">
	<breadcrumb></breadcrumb>

	<h1 class="title">Manage Groups & Music</h1>
	<i>Deliver the right music to the right musicians in a simple, organized way.</i>

	<ol>
		<li>Create the class(es) or group(s) you direct.</li>
		<li>Choose which songs the selected group will be working on.</li>
		<li>Add group members so they can receive assigned music through the A-ccompany app.</li>
	</ol>
</div>

<br>

<div>
	<groups
		[groups]='groups'
		[group]='group'
		[customer]='customer'
		(editGroupEmitter)='editGroup($event)'>
	</groups>

	<br>
	<br>

	<songs
		*ngIf='group && group.id'
		[group]='group'
		[customer]='customer'
	>
	</songs>

	<br>
	<br>

	<group-members
		*ngIf="group && group.id"
		[group]='group'
		[customer]='customer'
	>
	</group-members>

	<br>

	<div
			*ngIf="group && group.id"
			style="text-align: center;"
	>
		<div>An email has been sent to you with links and instructions to download the A-ccompany app.</div>
		<div>Please share the links and instructions with each member of the group and start moving the world through music.</div>
	</div>

	<br>

	<musician-access
			*ngIf="group && group.id && stateService.getUser().is_admin"
			[group]="group"
	></musician-access>

	<br>
	<br>

	<products *ngIf='group && group.id && stateService.getUser().is_admin'></products>

	<br>
	<br>
</div>
