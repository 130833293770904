<div id="main-container">
  <div
      class="group-container"
      *ngFor="let group of student.groups"
  >
    <div class="group-header">
      <h2>{{group.name}}</h2>
    </div>
    <app-song-selector-item
        *ngFor="let song of group.songs"
        [song]="song"
        [selected]="song.sku === selectedSku"
        (click)="changeSongEmitter.emit(song.sku)"
    ></app-song-selector-item>
</div>
