import { Musician } from './Musician';
import { Product } from './Product';

export class Group {
	id: string | null;
	name: string;
	genres: string[];
	productIds: string[];
	musicians: Musician[];

	constructor(
		id: string | null,
		name: string,
		genres: string[],
		productIds: string[],
		musicians: Musician[],
	) {
		this.id = id;
		this.name = name;
		this.genres = genres;
		this.productIds = productIds;
		this.musicians = musicians;
	}

	static fromObj(data: any): Group | null {
		try {
			if (!data.id) {
				return null;	
			}
			const group = new this(
				data.id,
				data.name || '',
				data.genres || [],
				data.products ? data.products.map((p: any) => p.id ) : [],
				data.musicians ? data.musicians.map((m: any) => Musician.fromDb(m)) : [],
			);
			return group;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	toDb(): any {
		const data: any = {};
		if (this.id)
			data.id = this.id;
		data.name = this.name;
		data.genres = this.genres;
		data.musicians = this.musicians.map(m => {return {...m}});
		return data;
	}

}
