import { UntypedFormGroup } from '@angular/forms';
import { AbstractControl, ValidatorFn } from "@angular/forms";

export function strongPasswordValidator(controlName: string): ValidatorFn {
  return (controls: AbstractControl) => {
    const control = controls.get(controlName);
    const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\.@$!%*?&])[A-Za-z\d\.@$!%*?&]{6,}$/;

    if (!strongRegex.test(control.value)) {
      control.setErrors({ strong: true });
      return { strong: true };
    } else {
      return null;
    }
  };
}

export function matchingPasswordValidator(
    controlName: string,
    matchingControlName: string
) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ matching: true });
      return {matching: true};
    } else {
      return null;
    }
  }
}
