import { Component, OnInit } from '@angular/core';
import { Product } from '../models/Product';
import { UntypedFormControl } from '@angular/forms';
import { FirestoreService } from '../services/firestore.service';
import { StateService } from '../services/state.service';
import { Customer } from '../models/Customer';
import { PackProduct, ProductPack } from '../models/ProductPack';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router } from '@angular/router';
import { Group } from '../models/Group';
import { Observable } from 'rxjs';
import { AccessRecord } from '../models/AccessRecord';


@Component({
  selector: 'song-list',
  templateUrl: './song-list.component.html',
  styleUrls: ['./song-list.component.scss']
})
export class SongListComponent implements OnInit {

	public group!: Group;
	public search = new UntypedFormControl();
	public productPack!: ProductPack;
	public products = new MatTableDataSource<Product>();
	public selection = new SelectionModel<Product>(true, [])
	public displayedColumns: string[] = [
		'select',
		'title',
		'sku',
		'genre',
	];
	public customer!: Customer;

  constructor(
		public firestoreService: FirestoreService,
		public stateService: StateService,
		private router: Router,
		private route: ActivatedRoute,
	) { }

	ngOnInit() {
		this.getGroup();
		this.stateService.getCustomerObservable().subscribe((customer: Customer | null) => {
			if (customer) {
				this.customer = customer;
				this.getProductPack();
			} else {
				this.router.navigate(['/dashboard']);
				throw 'Customer does not exist. Rerouting to dashboard to select a customer';
			}
		});
	}

	private getGroup() {
		this.firestoreService.getGroup(this.route.snapshot.paramMap.get('groupId'))
			.subscribe((group: Group | null) => {
				if (!group) {
					console.error('Group does not exist. Redirecting to manage page');
					this.router.navigate(['/manage']);
					return;
				}
				this.group = group;
				this.stateService.breadcrumbs.next([{
					name: `Home`,
					route: `/dashboard`,
				},{
					name: `Edit: ${this.group.name}`,
					route: `/manage/${this.group.id}`
				},{
					name: `Add Product`,
					route: `${this.group.id}/products`
				}]);
			});
	}

	private getProducts(): void {
		this.firestoreService.getProductItems().subscribe(
			(products: Product[]) => {
				this.products.data = products.filter((prod: Product) => {
					return (
						prod.genre === this.productPack.genre
						&& prod.type === 'Song'
					);
				});
				this.initializeSelection();
			}
		)
	}

	private getProductPack(): void {
		this.firestoreService.getProduct(
				this.route.snapshot.paramMap.get('productId'),
				this.route.snapshot.paramMap.get('groupId'),
		).subscribe((productPack: Product | null) => {
			if (!productPack) {
				this.router.navigate(
					[`/manage/${this.route.snapshot.paramMap.get('groupId')}`],
				);
				throw 'Product pack does not exist. Redirecting to manage page';
			} else if (!(productPack instanceof ProductPack)) {
				this.router.navigate(
					[`/manage/${this.route.snapshot.paramMap.get('groupId')}`],
				);
				throw 'Selected product is not a product pack. Redirecting to manage page';
			}

			this.productPack = productPack;
			this.getProducts();
		});
	}

	private initializeSelection() {
		const groupProductIds: string[] = this.productPack.groupProductItems.map(
				prod => prod.productItemId
		);
		for(let product of this.products.data) {
			if (product.id && groupProductIds.indexOf(product.id) !== -1) {
				this.selection.select(product);
			}
		}
	}

	public cancelProducts() {
		this.router.navigate([`/manage/${this.group.id}`])
	}

	public saveProducts() {
		const newGroupProducts: PackProduct[] = [];
		for (let product of this.selection.selected) {
			const packProduct: PackProduct = PackProduct.fromProduct(product);
			if (packProduct) {
				if (packProduct.groupIds.indexOf(this.group.id) === -1) {
					packProduct.groupIds.push(this.group.id);
				}
				newGroupProducts.push(packProduct);
			} else
				console.error(`Could not create a pack product from ${product.title}`);
		}
		if (newGroupProducts.length > this.productPack.productItems) {
			window.alert(`Adding the product${newGroupProducts.length > 1 ? '' : 's'} (${newGroupProducts.length}) would exceed the available ${this.productPack.productItems} licenses. Please select ${this.productPack.productItems} or less`)
			return;
		}
		this.productPack.groupProductItems = newGroupProducts;
		this.productPack.productAssigned = this.productPack.groupProductItems.length;

		this.firestoreService.saveProduct(this.productPack, this.customer.id)
			.subscribe((product: Product | null) => {
				if (!product) {
					throw 'Could not update the given product pack';
				}

				this.group.musicians.forEach((m) => {
					this.firestoreService.getMusicianAccess(m.email)
					.subscribe((ma) => {
						var record = ma.find((r) => r.groupId == this.group.id);
						record['access'] = newGroupProducts.map((ngp) => {
							return {expiration: ngp.expiresOn, sku: ngp.sku};
						});
						this.firestoreService.saveMusicianAccess(m.email, ma)
							.subscribe((final) => {});
					});
				});
				this.router.navigate([`/manage/${this.group.id}`]);
			}
		);
	}

	applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.products.filter = filterValue.trim().toLowerCase();
  }

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.products.data.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if(this.isAllSelected()) {
			this.selection.clear();
			return;
		}
		this.selection.select(...this.products.data);
	}

	checkboxLabel(row?: Product): string {
		if (!row) {
			return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
	}
}
