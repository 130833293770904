<div id="main-container">
  <h1 id="song-title">{{song.title}}</h1>

  <div id="playlistDiv"></div>

  <div id="button-bar" style="margin:auto; width: 168px;">
    <div class="playlist-toolbar" layout="row" style="position: relative">
<!--       <button
          mat-button
          (mousedown)="rewind()"
          style="left: -10px;"
      >
        <mat-icon aria-hidden="true">fast_rewind</mat-icon>
      </button> -->
      <button id="pauseBtn"
          mat-button
          (mousedown)="pause()"
          style="left: 25px;"
      >
        <mat-icon>pause</mat-icon>
      </button>
      <button
          mat-button
          (mousedown)="play()"
          style="left:60px;"
      >
        <mat-icon>play_arrow</mat-icon>
      </button>
      <button
          mat-button
          (mousedown)="stop()"
          style="left:95px;"
      >
        <mat-icon>stop</mat-icon>
      </button>
  <!--     <button
          mat-button
          (mousedown)="fastForward()"
          style="left:130px;"
      >
        <mat-icon aria-hidden="true">fast_forward</mat-icon>
      </button> -->
    </div>
    <div class="playlist-toolbar">
      <span id="timestamp">{{timestamp}}</span>
    </div>
    <div class="playlist-toolbar">
      <mat-slider
        value="100"
        color="primary"
        (input)="changeMasterVolume($event)"
      ></mat-slider>
      <mat-checkbox
          id="auto-scroll"
          color="primary"
          (change)="changeAutoScroll($event)"
      >Automatic Scroll</mat-checkbox>
    </div>
  </div>
</div>