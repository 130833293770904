import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { StudentService } from "./student.service";
import { User } from '../models/User';
import { FirestoreService } from "./firestore.service";
import { StateService } from "./state.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { from, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { PlayerSongRef } from "../models/PlayerSongRef";
import { FunctionService } from "./functions.service";
import { Customer } from "../models/Customer";

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	userData: any;

	constructor(
		public firestore: AngularFirestore,
		public firestoreAuth: AngularFireAuth,
		private firestoreService: FirestoreService,
		private functionService: FunctionService,
		public stateService: StateService,
		private studentService: StudentService,
		private router: Router,
	){
		this.firestoreAuth.authState.subscribe(user => {
			if (user) {
				this.userData = user;
				localStorage.setItem('user', JSON.stringify(this.userData));
				JSON.parse(localStorage.getItem('user'));
			} else {
				localStorage.setItem('user', null);
				JSON.parse(localStorage.getItem('user'));
			}
		});
	}

	public signIn(email: string, password: string) {
		return this.firestoreAuth.signInWithEmailAndPassword(email, password)
			.then((result) => {
				this.setUserData(result.user);
			}).catch((error) => {
				window.alert(error.message);
			});
	}

	public studentSignIn(student_code: string) {
		const emailRegex = /[a-zA-Z0-9.\-_]*@[a-zA-Z0-9.\-_]*/;  // Simple email regex
		if (emailRegex.test(student_code)) {
			this.firestoreService.getMusicianAccess(student_code)
				.subscribe((res: any) => {
					this.firestoreAuth.signInWithEmailAndPassword(
						environment.studentCreds.email,
						environment.studentCreds.password,
					).catch((error) => {
						window.alert(error.message);
						this.router.navigate(['']);
						return;
					});
					this.studentService.fromAccessRecord(student_code, res);
				}, (err: any) => {
					window.alert(`Could not find a record on file with the email ${student_code}. It can take up to one hour for the database to update once given access to A-ccompany. Please check your information or wait and try again.`);
					return false;
				});
		} else {
			this.firestoreService.songFromPromoCode(student_code).subscribe(
				(song: PlayerSongRef | null) => {
					if (!song) {
						window.alert(`Could not find a record on file for access code ${student_code}. Either enter a valid email address with access privileges, or contact your instructor to get the correct code`);
						return;
					}
					this.firestoreAuth.signInWithEmailAndPassword(
						environment.studentCreds.email,
						environment.studentCreds.password
					).catch((error) => {
						window.alert(error.message);
						this.router.navigate(['']);
						return;
					});
					this.studentService.fromSongRef(song);
				}
			)
		} 
	}

	public signUp(email: string, password: string) {
		return this.firestoreAuth.createUserWithEmailAndPassword(email, password)
			.then((result: any) => {
				this.functionService.getShopifyCustomer(email).subscribe(  // Creates customer
					(res: string) => {
						// Reload purchases on signup since they aren't already for some reason
						this.firestoreService.getCustomer(email).subscribe(
							(cust: Customer) => {
								this.functionService.getRefreshOrders(cust.id).subscribe();
							},
						);

						this.signIn(email, password).then(() => this.router.navigate(['/manage']));
					},
					(err: any) => {
						window.alert(err.message || err);
					}
				);
			}).catch((error) => {
				window.alert(error.message || error);
			})
	}

	public forgotPassword(email: string) {
		return this.firestoreAuth.sendPasswordResetEmail(email)
			.then((result) => {
				window.alert('An email has been sent to your inbox with instructions on resetting your password');
			}).catch((error) => {
				window.alert(error.message);
			});
	}

	public checkActionCode(oobCode: string): Observable<boolean> {
		return from(this.firestoreAuth.verifyPasswordResetCode(oobCode)
			.then(email => {
				return true;
			}).catch((err: any) => {
				return false;
			}));
	}

	public changePassword(oobCode: string, password: string): Observable<boolean> {
		this.firestoreAuth.currentUser = null;
		return from(this.firestoreAuth.confirmPasswordReset(oobCode, password)
			.then((res: any) => {
				return true;
			}).catch((err: any): boolean => {
				console.error(err);
				return false;
			}));
	}

	private setUserData(user) {
		const userRef: AngularFirestoreDocument<any> = this.firestore.doc(`users/${user.uid}`);
		const userData: User = {
			email: user.email,
			name: '', 
			is_admin: false,
			is_director: true,
		}
		this.stateService.setUser(userData);
		
		return userRef.set(userData, {
			merge: true
		});
	}

	public signOut(): void {
		this.firestoreAuth.signOut().then(() => {
			localStorage.removeItem('user');
			localStorage.removeItem('accompany-student');
			this.studentService.signOut();
			this.stateService.signOut();
			console.info('Sign Out Successful');
		});
	}

	public verifyEmail(oobCode: string): Observable<boolean> {
		return from(this.firestoreAuth.applyActionCode(oobCode)).pipe(
			map((res: any) => {
				return true;
			}),
			catchError((err: any): Observable<boolean> => {
				console.error(err);
				return of(false); 
			}),
		);
	}

}
