<div fxLayout="column" fxFlexFill>
	<h1 class="title">Welcome</h1>

	<mat-table
			[dataSource]="customers"
			class="mat-elevation-z8"
			style="width: 100%;"
	>
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef> Name/customerId </mat-header-cell>
			<mat-cell *matCellDef="let customer">
				<div class="customer-col">
					<a>{{customer.name}}</a>
					{{customer.id}}
				</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="email">
			<mat-header-cell *matHeaderCellDef> emailAddress </mat-header-cell>
			<mat-cell *matCellDef="let customer">{{customer.email}}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell *matCellDef="let customer">
				<button mat-stroked-button (click)="reloadPurchases(customer)">Reload Purchases</button>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row
				*matRowDef="let row; columns: displayedColumns;"
				class="table-row"
				(click)="mockCustomer(row)"
		></mat-row>
	</mat-table>
</div>
