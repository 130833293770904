<form [formGroup]="passwordForm" (ngSubmit)="submit('submit')">
  <h1 class="title">Reset Password</h1>
  <div class="form-body">
    <h2>Please enter and confirm your new password</h2>
    <h3
        *ngIf="passwordForm.errors && passwordForm.errors.strong && submitted"
        class="primary-text"
    >
      Your password must be at least 6 characters long,
      <br>contain 1 upper case, 1 lower case,
      <br>and one of .@$!%*?&
    </h3>
    <div class="input-row">
      <input
          type="password"
          formControlName="password"
          class="form-control"
          placeholder="Password"
          required
          autofocus
      >
      <h3
          *ngIf="passwordForm.errors && passwordForm.errors.matching && !passwordForm.errors.strong && submitted"
          class="primary-text"
      >Your passwords do not match</h3>
      <input
          type="password"
          formControlName="confirm"
          class="form-control"
          placeholder="Confirm Password"
          required
          autofocus
      >
      <div class="submit-row">
        <button
            mat-flat-button
            class="submit-button"
            type="submit"
        >Submit</button>
        <button
            mat-flat-button
            class="submit-button"
            (click)="submit('cancel')"
        >Cancel</button>
      </div>
    </div>
  </div>
</form>
