export class Product {
	id: string;
	title: string;
	sku: string;
	genre: string;
	type: string;
	assigned: number;
	quantity: number;
	add: boolean = false;
	expires: number | null;
	groupIds: string[];
	variants: ProductVariant[];
	available: number;

	public constructor(
			id: string,
			title: string,
			sku: string,
			genre: string,
			type: string,
			assigned: number,
			quantity: number,
			expires: number | null,
			groupIds: string[],
			variants: ProductVariant[],
			available: number,
	) {
		this.id = id;
		this.title = title;
		this.sku = sku;
		this.genre = genre;
		this.type = type;
		this.assigned = assigned || 0;
		this.quantity = quantity;
		this.expires = expires;
		this.add = false;
		this.groupIds = groupIds;
		this.variants = variants;
		this.available = available;
	}

	static fromObject(data: any): Product | null {
		try {
			if (!data.variants)
				data.variants = [];
			return new this(
				data.id,
				data.title,
				data.sku,
				data.genre,
				data.type,
				Number(data.assigned),
				Number(data.available) + Number(data.assigned),
				data.expiresOn ? data.expiresOn.seconds * 1000 : null,  // Make into milliseconds
				data.groupIds || [],
				data.variants.map(v => {
					return {
						available: v.available || null,
						genre: v.genre || null,
						performance: v.performance || null,
						sku: v.sku || null,
						title: v.title || null,
						type: v.type || null,
					} as ProductVariant;
				}),
				Number(data.available),
			)
		} catch (error) {
			console.error(data);
			console.error(error);
			return null;
		}
	}
}

export interface ProductVariant {
	available: number | null;
	genre: string | null;
	performance: boolean | null;
	sku: string | null;
	title: string | null;
	type: string | null;
}
