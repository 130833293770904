import { Component, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from '../models/Customer';
import { Group } from '../models/Group';
import { Product } from '../models/Product';
import { PackProduct, ProductPack } from '../models/ProductPack';
import { FirestoreService } from '../services/firestore.service';

@Component({
  selector: 'songs',
  templateUrl: './songs.component.html',
  styleUrls: ['./songs.component.scss']
})
export class SongsComponent {

	@Input() group?: Group;
	@Input() customer?: Customer;
	public title: string = '2. Choose Songs';
	public products?: Product[];
	public displayedColumns: string[] = ['name', 'action'];


  constructor(
		private firestoreService: FirestoreService,
		private router: Router,
	) {
	}

	ngOnChanges(changes: SimpleChanges): void {
		for (const change in changes) {
			if (change === 'group') {
				this.group = changes[change].currentValue;
				if (this.group && this.group.productIds && this.customer && this.customer.id) {
					this.firestoreService.getProducts(
							this.customer.id,
							this.group.productIds,
							this.group.id
					).subscribe((products: (Product | ProductPack)[]) => {
						this.products = products;
					});
				}
			} else if (change === 'customer') {
				this.customer = changes[change].currentValue;
			}
		}
	}

	public addSongs(product: Product) {
		this.router.navigate([`/manage/${this.group.id}/${product.id}`])
	}

	public removeSong(product: ProductPack, song: PackProduct) {
		product.groupProductItems.splice(product.groupProductItems.indexOf(song), 1);
		product.productAssigned = product.groupProductItems.length;
		this.firestoreService.saveProduct(product, this.customer.id).subscribe(
			_ => {},
			(err: any) => {
				product.groupProductItems.push(song);
				window.alert(`Could not remove ${song.title} from the product pack`);
			}
		);
	}

	public removeProduct(product: Product) {
		if (this.group && this.customer.id) {
			this.group.productIds.splice(this.group.productIds.indexOf(product.id), 1);
			if (this.group && this.group.productIds && this.customer && this.customer.id) {
				this.firestoreService.saveGroup(this.group, this.customer.id)
					.subscribe((group: Group) => {
						this.group = group;
						this.firestoreService.getProducts(
								this.customer.id,
								this.group.productIds,
								this.group.id
						).subscribe((products: (Product | ProductPack)[]) => {
							this.products = products;
						});
					});
			}
		} else {
			window.alert('ERROR: Customer is not set. Redirecting');
			this.router.navigate(['/dashboard']);
			throw 'Remove product called before group and customer were set';
		}
	}

	isProductPack(prod: Product): boolean {
		return prod instanceof ProductPack;
	}
}
