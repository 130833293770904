import { TMidiFile } from "./TMidiFile";

export class DecompressedSong {
  sku: string;
  title: string;
  tracks: Track[];
  midi: TMidiFile | null;

  constructor (
    sku: string,
    title: string,
    tracks: Track[],
    midi?: TMidiFile,
  ) {
    this.sku = sku;
    this.title = title;
    this.tracks = tracks;
    this.midi = midi || null;
  }

  static fromObj(data: any): DecompressedSong | null {
    try {
      return new this(
        data.sku,
        data.title,
        data.tracks,
        data.midi || null,
      );
    } catch (err: any) {
      console.error(err);
      return null;
    }
  }
}

export class Track {
  name: string;
  src: string;

  constructor (
    name: string,
    src: string,
  ) {
    this.name = name;
    this.src = src;
  }

  static fromObj(data: any): Track | null {
    try {
      return new this(
        data.name,
        data.src,
      );
    } catch (err: any) {
      console.error(err);
      return null;
    }
  }
}
