import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StudentService } from '../services/student.service';
import { Student } from '../models/Student';
import { SongService } from '../services/song.service';
import { DecompressedSong } from '../models/DecompressedSong';
import EventEmitter from 'event-emitter';
import { RehearsalMark } from '../models/RehearsalMark';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {
  public selectedSku?: string;
  public selectedMarker?: RehearsalMark;
  public student!: Student;
  public song?: DecompressedSong;
  public loading: boolean = false;
  public playerEvents: EventEmitter = new EventEmitter();

  constructor(
    private studentService: StudentService,
    private router: Router,
    private songService: SongService,
  ) {
    this.student = this.studentService.getStudent();
    if (!this.student) {
      this.router.navigate(['']);
    }
  }

  ngOnInit(): void { }

  changeSong(sku: string) {
    this.song = null;
    this.loading = true;
    this.playerEvents = new EventEmitter();
    this.selectedSku = sku;
    this.songService.getSong(sku)
      .subscribe(
        (song: DecompressedSong) => {
          this.song = song;
          this.loading = false;
        }),
        (err: any) => {
          window.alert('Error retrieving song data from the database. Please try again');
        };
  }

  changeMarker(marker: RehearsalMark) {
    this.selectedMarker = marker;
    this.playerEvents.emit('play', marker.elapsedSeconds);
  }

}
