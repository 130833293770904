import { Component } from '@angular/core';
import { Product } from '../models/Product';
import { FirestoreService } from '../services/firestore.service';


@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {

	public products!: Product[];
	public displayedColumns: string[] = ['name', 'sku', 'genre', 'type']

  constructor(
		private firestoreService: FirestoreService,
	) {
		this.getProducts();
	}

	getProducts() {
		this.firestoreService.getProductItems()
			.subscribe((products: Product[]) => {
				this.products = products;
			});
	}
}
