import { Component, HostListener, Input, OnInit } from "@angular/core";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { MatLegacySliderChange as MatSliderChange } from "@angular/material/legacy-slider";
import EventEmitter from "event-emitter";
import { Observable, from } from "rxjs";
import WaveformPlaylist from "waveform-playlist";
import { DecompressedSong } from "../models/DecompressedSong";

@Component({
	selector: "audio-player",
	templateUrl: "./audio-player.component.html",
	styleUrls: ["./audio-player.component.scss"],
})
export class AudioPlayerComponent implements OnInit {
	@Input() song!: DecompressedSong;
	@Input() playerEvents!: EventEmitter;
	public timestamp: string = "00:00:00:000";
	public playlist: any;
	constructor() {}

	ngOnInit() {
		this.setupPlaylist().subscribe(() => {
			this.playerEvents.on("timeupdate", (seconds: number) => {
				this.timestamp = new Date(seconds * 1000).toISOString().substr(11, 12);
			});
		});
	}

	ngOnDestroy() {
		this.playerEvents.emit("stop");
	}

	changeMasterVolume(change: MatSliderChange) {
		if (change.value) {
			this.playerEvents.emit("mastervolumechange", change.value);
		}
	}

	changeAutoScroll(change: MatCheckboxChange): void {
		this.playerEvents.emit("automaticscroll", change.checked);
	}

	@HostListener("window:resize", ["$event"])
	onWindowResize(event) {
		var elements = document.getElementsByClassName(
			"controls"
		) as HTMLCollectionOf<HTMLElement>;
		if (window.innerWidth > 830) {
			var timeScaleElements = document.getElementsByClassName(
				"playlist-time-scale"
			) as HTMLCollectionOf<HTMLElement>;
			if (timeScaleElements.length > 0) {
				// should only be one.
				for (var i = 0; i < timeScaleElements.length; i++) {
					timeScaleElements[i].style.visibility = "visible";
				}
			}
			if (elements.length > 0 && elements[0].offsetWidth != 200) {
				for (var i = 0; i < elements.length; i++) {
					elements[i].style.width = "200px";
				}
			}
		} else {
			// set to whatever is appropriate.
			var playlistWidth =
				document.getElementById("playlistDiv").offsetWidth.toString() + "px";
			for (var i = 0; i < elements.length; i++) {
				elements[i].style.width = playlistWidth;
			}
			var timeScaleElements = document.getElementsByClassName(
				"playlist-time-scale"
			) as HTMLCollectionOf<HTMLElement>;
			if (timeScaleElements.length > 0) {
				for (var i = 0; i < timeScaleElements.length; i++) {
					timeScaleElements[i].style.visibility = "hidden";
				}
			}
		}
	}

	private setupPlaylist(): Observable<any> {
		// get size of "playlistDiv" set it to that if size of tablet or smaller
		let controlWidth = document.getElementById("playlistDiv").offsetWidth;
		if (window.innerWidth > 830) {
			controlWidth = 200;
		}

		this.playlist = WaveformPlaylist(
			{
				samplesPerPixel: 3000,
				zoomLevels: [500, 1000, 3000, 5000],
				timescale: true,
				waveHeight: 112,
				container: document.getElementById("playlistDiv"),
				colors: {
					waveOutlineColor: "#E0EFF1",
					timeColor: "grey",
					fadeColor: "black",
				},
				controls: {
					show: true,
					width: controlWidth,
					widgets: {
						stereoPan: false,
						collapse: false,
						remove: false,
					},
				},
			},
			this.playerEvents
		);
		return from(this.playlist.load(this.song.tracks));
	}

	public play(): void {
		this.playerEvents.emit("play");
	}

	public pause(): void {
		this.playerEvents.emit("pause");
	}

	public stop(): void {
		if( window.innerWidth > 1400) {
			this.playerEvents.emit("stop");
		}
		else { // stop is broken on mobile, so instead trigger end of song so it can be restarted by pressing play
			var markers = document.getElementsByTagName('app-measure-marker-item');
			if(markers.length > 1) {
				var endMarker = markers[markers.length - 1] as HTMLElement;
				console.log(endMarker);
				endMarker.click();
			}
		}
	}

	public fastForward(): void {
		this.playerEvents.emit("fastforward");
	}

	public rewind(): void {
		this.playerEvents.emit("rewind");
	}
}
