import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { matchingPasswordValidator, strongPasswordValidator } from "../validators/password.validator";

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [
    '../login/login.component.scss',
    './reset-password.component.scss',
  ],
})
export class ResetPasswordComponent implements OnInit {
	public passwordForm!: UntypedFormGroup;
  public submitted: boolean = false;
  private oobCode?: string;

  constructor(
    builder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
  ) {
    this.passwordForm = builder.group({
      password: ['', Validators.required],
      confirm: ['', Validators.required],
    }, {
      validators: [
        matchingPasswordValidator('password', 'confirm'),
        strongPasswordValidator('password'),
      ],
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      if (params.mode !== 'resetPassword') {
        this.router.navigate(['/']);
        throw 'Mode is not resetPassword. You should not be here';
      }
      this.auth.checkActionCode(params.oobCode).subscribe((valid: boolean) => {
        if (!valid) {
          this.router.navigate(['/']);
          window.alert('The link you used has expired. Please request another password reset');
        }
        this.oobCode = params.oobCode;
      });
    });
  }

  submit(action: 'cancel' | 'submit') {
    this.submitted = true;
    if (action === 'cancel') {
      this.router.navigate(['/']);
      return;
    } else if (!this.passwordForm.valid) {
      return;
    }
    this.auth.changePassword(this.oobCode, this.passwordForm.get('password').value)
      .subscribe((success: boolean) => {
        if (success) {
          window.alert('Success! Your password has been reset');
        } else {
          window.alert('Failed to reset your password. Please request another password reset link');
        }
        this.router.navigate(['/']);
      });
  }

}
