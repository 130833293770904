import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { Observable, of } from "rxjs";

import { StateService } from './services/state.service';
import { parseURL } from './util';


@Injectable()
export class AuthGuard  {
	constructor(
		private router: Router,
		private stateService: StateService,
	) {}

	canActivate(): Observable<boolean> {
		this.stateService.previousRouteInfo = parseURL(window.location.pathname);

		if (this.stateService.getUser() === null) {
			this.router.navigate(['/']);
		} else {
			return of(true);
		}
	}
}
