import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Customer } from '../models/Customer';
import { forkJoin, from, Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap } from 'rxjs/operators';
import { Group } from "../models/Group";
import { Product } from "../models/Product";
import { ProductPack } from "../models/ProductPack";
import { PlayerSongRef } from "../models/PlayerSongRef";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { HttpClient } from "@angular/common/http";


@Injectable({
	providedIn: 'root'
})
export class FirestoreService {

	productItems: Product[] = []; //add Product Item model

	constructor(
		public firestore: AngularFirestore,
		private fireStorage: AngularFireStorage,
		private http: HttpClient,
	){}

	public getGenres() {
		this.firestore.collection("genres").get().subscribe(
			(result) => {
				return result;
			}
		);
	}

	public getCustomers(): Observable<Customer[]>{
		return this.firestore.collection("customer").get().pipe(
			map(
				(results: any) => {
					let customers = [];
					results.forEach((doc: any) => {
						customers.push({
							id: doc.id,
							name: doc.data().name,
							email: doc.data().emailAddress,
						});
					});

					return customers;
				}
			),
		)
	}

	public getCustomer(email: string): Observable<Customer | null> {
		return this.firestore.collection("customer", ref => ref.where('emailAddress', '==', email)).get()
			.pipe(
				map((response: any) => {
					const data = response.docs[0];
					if (!data.id)
						throw {message: 'Customer does not have an id and cannot be retrieved'};
					return {
						id: data.id,
						name: data.data().name,
						email: data.data().emailAddress,
					};
				}),
				catchError((err: any) => {
					window.alert(err.message)
					return of(null);
				}),
			);
	}

	public getProduct(productId: string, groupId?: string): Observable<Product | null> {
		return this.firestore.collection('product').doc(productId).get().pipe(
			map((res: any) => this._dataToProduct(res.id, res.data(), groupId)),
		);
	}

	public getProducts(
			customerId: number,
			productIds?: string[],
			groupId?: string,
	): Observable<Product[]> {
		const customer = this.firestore.collection("customer").doc(customerId + '');
		return this.firestore.collection(
				"product",
				ref => ref.orderBy('title').where('customer', '==', customer.ref)
		)
			.get()
			.pipe(
				map((results: any) => {
					const productList: (Product | ProductPack)[] = [];
					results.forEach((doc: any) => {
						if (!productIds || productIds.includes(doc.id)) {
							const prod: ProductPack | Product | null = this._dataToProduct(
									doc.id, doc.data(), groupId
							);
							if (prod)
								productList.push(prod);
						}
					});
					return productList;
				}),
			);
	}

	public getSongRecords(skus: string[]): Observable<PlayerSongRef[]> {
		let badSku;
		while ((badSku = skus.indexOf('')) && badSku !== -1) {
			skus.splice(badSku, 1);
		}

		return from(Promise.all(
			skus.map(sku => this.firestore.collection(
				'productitem',
				ref => ref.orderBy(`skuList.${sku}`)
			).get())
		)).pipe(
			switchMap((observables: Observable<any>[]) => {
				return forkJoin(observables)
					.pipe(
						map((res: any[]) => {
							const songRecords = [];
							for (let i = 0; i < res.length; i++) {
								res[i].forEach(doc => songRecords.push({
									sku: skus[i],
									title: doc.data().title
								} as PlayerSongRef));
							}
							return songRecords;
						})
					)
			})
		)
	}

	public saveProduct(
			product: Product | ProductPack,
			customerId: number,
	): Observable<Product | null> {
		if (!customerId) {
			console.error('Cannot save a product without a customer');
			return of(null);
		}
		const customer = this.firestore.doc(`customer/${customerId}`).ref;
		if (product instanceof Product) {
			const productDoc = this.firestore.collection('product').doc(product.id);
			const updateVals: Record<string, any> = {
				available: product.available,
				assigned: product.assigned,
			};
			if (product instanceof ProductPack) {
				updateVals.groupProductItems = product.groupProductItems.map(prod => {
					return {...prod};
				});
			}

			return from(productDoc.update(updateVals))
				.pipe(
					switchMap((): Observable<Product> => {
						return this.firestore.collection('product').doc(product.id)
							.get()
							.pipe(
								map((doc: any) => {
									const data = doc.data();
									if (data.groupProductItems) {
										return ProductPack.fromObject({ id: doc.id, ...data });
									}
									return Product.fromObject({ id: doc.id, ...data});
								}),
						);
					}),
				);
		}
		return of(null);
	}

	public getGroups(customerId: number): Observable<Group[]> {
		const customer = this.firestore.collection("customer").doc(customerId + '');
		return this.firestore.collection(
				'group',
				ref => ref.where('customer', '==', customer.ref)
		)
			.get()
			.pipe(
				map((results: any) => {
					const groups: Group[] = [];
					results.forEach((doc: any) => {
						const data = doc.data();
						const group = Group.fromObj({ id: doc.id, ...data });
						if(group)
							groups.push(group);
					});
					return groups;
				}),
			)
	}

	public getGroup(groupId: string): Observable<Group | null> {
		return this.firestore.collection('group').doc(groupId+'')
			.get()
			.pipe(
				map((res: any) => {
					if (res.data()) {
						return Group.fromObj({ id: res.id, ...res.data() })
					}
					console.error(`Invalid group id: ${groupId}`)
					return null;
				}),
			);
	}

	public saveGroup(
			groupData: Group, customerId: number
	): Observable<Group> {
		const customer = this.firestore.doc(`customer/${customerId}`).ref;
		let products: any = [];
		for (let id of groupData.productIds) {
			products.push(this.firestore.collection("product").doc(id).ref);
		}

		if (!groupData.id && customerId) {
			return from(this.firestore.collection('group').add({
				...groupData.toDb(),
				products: products,
				customer: customer,
			})).pipe(
				switchMap((res: any): Observable<Group> => {
					return this.getGroup(res.id);
				}),
			);
		} else if (groupData.id) {
			const data: any = groupData.toDb();
			data.products = products;
			if (customer.id)
				data.customer = customer;

			return from(
				this.firestore.collection('group')
					.doc(data.id + '')
					.update(data)
			).pipe(
				switchMap((): Observable<Group> => this.getGroup(data.id) ),
			)
		} else {
			throwError
		}
	}

	private trimmedString(original: string) {
		return (original||'').trim().toLowerCase();
	}

	public deleteGroup(groupId: string, groupName: string): boolean {
		let title = `Enter "${groupName}" or "delete" to confrim the deletion of the group. This action cannot be undone.`;
		let answer = this.trimmedString(window.prompt(title));

		if ( !(answer === 'delete' || answer == this.trimmedString(groupName))) {
			return false;
		}

		this.firestore.collection('group').doc(groupId+'').delete();
		return true;
	}

	public getProductItems(): Observable<Product[]> {
		if (this.productItems && this.getProductItems.length > 0) {
			return of(this.productItems);
		} else {
			this.productItems = [];
			return this.firestore.collection('productitem').get()
				.pipe(
					map((results: any) => {
						results.forEach((doc) => {
								const data: any = doc.data();
								let prod: ProductPack | Product | null = null;
								if (data.groupProductItems) {
									prod = ProductPack.fromObject({ id: doc.id, ...data });
								} else {
									prod = Product.fromObject({ id: doc.id, ...data })
								}

								if (prod) {
									this.productItems.push(prod);
								}
						});
						return this.productItems;
					}),
				);
		}
	}

	public getMusicianAccess(email: string): Observable<{access: Object[]; groupId: string}[]> {
		return this.firestore.collection('musicianAccess').doc(email.toLowerCase()).get()
			.pipe(
				map(
					(res: any) => {
						if (res.data())
							return res.data().groupAccess || [];
						return [];
					}
				)
			);
	}

	public getMusicFiles(sku: string): Observable<File> {
		return from(this.fireStorage.ref(`songs/${sku}.zip`).getDownloadURL())
			.pipe(
				switchMap((url: string) => {
					return this.http.get(url, {observe: 'response', responseType: 'blob'}).pipe(
						map((res: any) => {
							return new File([res.body], `${sku}.zip`, {type: 'application/zip'})
						}),
						catchError((err: any, caught: Observable<any>) => {
							throw err
						})
					);
				}),
				catchError((err: any, caught: Observable<any>) => {
					throw err
				})
			);
	}

	public saveMusicianAccess(
			musicianEmail: string,
			access: {access: Object[]; groupId: string}[]
	) {
		musicianEmail = musicianEmail.toLowerCase().trim();
		const musicianAccess = this.firestore
			.collection('musicianAccess')
			.doc(musicianEmail);
		return from(musicianAccess.set({
			emailAddress: musicianEmail,
			groupAccess: access
		})).pipe(
			switchMap(() => {
				return this.getMusicianAccess(musicianEmail);
			}),
		);
	}

	public deleteMusicianAccess(musicianEmail: string, groupId: string): Observable<any> {
		return this.getMusicianAccess(musicianEmail).pipe(
			switchMap((access: {access: Object[]; groupId: string}[]): Observable<any> => {
				const newAccess = access.filter(record => record.groupId !== groupId);
				return this.saveMusicianAccess(musicianEmail, newAccess);
			}),
		)
	}

	public songFromPromoCode(promoCode: string): Observable<PlayerSongRef | null> {
		return this.firestore
			.collection('productitem', ref => ref.where('sku', '==', promoCode))
			.get()
			.pipe(
				map((result: any) => {
					if (!result.docs || result.docs.length === 0)
						return null;
					return {
						sku: result.docs[0].data().sku,
						title: result.docs[0].data().title,
					} as PlayerSongRef;
				}),
		);
	}

	private _dataToProduct(
			id: string, data: Record<string, any>, groupId?: string,
	): Product | ProductPack | null {
		let prod: Product | ProductPack | null = null;
		if (data.groupProductItems ||  data.type === 'All Access') {
			if (!data.groupProductItems) {
				console.warn('Received an All Access product without a group product items list. We\'ll try and make and work');
				data.groupProductItems = [];
				data.assigned = data.assigned || 0;
				data.productAssigned = data.groupProductItems.length;
			}
			if (groupId) {
				data.groupProductItems = data.groupProductItems.filter(
					(productItem: any) => productItem.groupIds.indexOf(groupId) !== -1
				);
			}
			prod = ProductPack.fromObject({ id: id, ...data });
		} else {
			prod = Product.fromObject({ id: id, ...data });
		}
		return prod;
	}
}
