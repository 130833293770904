import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './AuthGuard';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ManageComponent } from './manage/manage.component';
import { ProductListComponent } from './product-list/product-list.component';
import { PlayerComponent  } from './player/player.component';
import { SongListComponent } from './song-list/song-list.component';


const routes: Routes = [
	{
		path: '',
		component: LoginComponent,
	},
	{
		path: 'verifyemail',
		component: LoginComponent,
	},
	{
		path: 'dashboard',
		component: DashboardComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'manage',
		component: ManageComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'manage/:groupId',
		component: ManageComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'manage/:groupId/products',
		component: ProductListComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'manage/:groupId/:productId',
		component: SongListComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'player',
		component: PlayerComponent,
	},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
