import { Component, HostListener } from "@angular/core";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent {
	title = "A-ccompany";
	isPhoneSized: boolean;

	constructor() {
		this.checkWindowSize();
	}

	@HostListener("window:resize", ["$event"])
	onWindowResize(event: Event) {
		this.checkWindowSize();
	}
	checkWindowSize() {
		this.isPhoneSized = window.innerWidth < 576;
	}
}
