<mat-card class="mat-elevation-z0">
	<mat-card-header>
		<mat-card-title>
			{{title}}
		</mat-card-title>
		<button
				*ngIf="group != null"
				mat-flat-button
				style="color: #FFFFFF; background-color: #ee3041;"
				(click)="showAddMusician()"
		><mat-icon>add_circle</mat-icon>Add Musician</button>
	</mat-card-header>
	<div class="card-content">
		<div
				*ngIf="group == null"
				style="width: 95%; display:flex; justify-content: start; margin: 20px;"
		>
			Select or create a group above
		</div>

		<div
				*ngIf="group != null"
				style="width: 95%; display:flex; flex-direction: column; justify-content: center;"
		>
			<p>
				<br>
				All group members must download the A-ccompany app (available for both Apple and Android devices).
				Members need to login to the A-ccompany app using their email entered below.
				All selected songs for this group will automatically download to each group member’s device.
			</p>

			<div
					*ngIf="group != null && new"
					style="width: 100%; padding: 10px;"
			>
				<div style="display: flex; flex-direction: row; justify-content: space-between;">
					<div style="display: flex; flex-direction: column;">
						<mat-label>Member</mat-label>
						<input
								[formControl]="name"
								class="form-control"
								required
						>
					</div>
					<div style="display: flex; flex-direction: column;">
						<mat-label>Email</mat-label>
						<input [formControl]="email" class="form-control" required>
					</div>
					<div style="display: flex; flex-direction: column;">
						<mat-label>Instrument</mat-label>
						<mat-select
								[formControl]="instrument"
								class="form-control"
								placeholder="Select instrument(s)."
								multiple
						>
							<mat-select-trigger>
								{{instrument.value && instrument.value.length > 0? instrument.value.join(", ") : 'Select instrument(s).'}}
							</mat-select-trigger>
							<mat-option
									*ngFor="let instrument of instrument_options"
									class="mat-primary"
									[value]="instrument"
							>
								{{instrument}}
							</mat-option>
						</mat-select>
					</div>
					<div style="display: flex; flex-direction: row; align-items: center;">
						<button
								mat-flat-button
								style="background-color: #dddddd; margin-right: .5rem"
								(click)="cancelNewMusician()"
						>Cancel</button>
						<button
								mat-flat-button
								style="background-color: #ee3041; color: #FFFFFF;"
								(click)="saveMusician()"
						>Save</button>
					</div>
				</div>
			</div>


			<mat-table [dataSource]="group.musicians" class="mat-elevation-z0" *ngIf="group != null">
				<ng-container matColumnDef="musician">
					<mat-header-cell *matHeaderCellDef>Member</mat-header-cell>
					<mat-cell *matCellDef="let musician">
						{{musician.name}}
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="email">
					<mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
					<mat-cell *matCellDef="let musician">
						{{musician.email}}
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="instrument">
					<mat-header-cell *matHeaderCellDef>Instrument</mat-header-cell>
					<mat-cell *matCellDef="let musician">
						{{musician.instrument}}
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="action">
					<mat-header-cell *matHeaderCellDef></mat-header-cell>
					<mat-cell *matCellDef="let musician">
						<a (click)="removeMusician(musician)">Remove Member</a>
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>


				<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
			</mat-table>
		</div>
	</div>
</mat-card>
