import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class FunctionService {

	functionUrl: string = 'https://us-central1-a-ccompany.cloudfunctions.net';

	constructor(
		private http: HttpClient,
	) {}

	public isAdminCheck(email: string): Observable<boolean> {
		var url = this.functionUrl + '/isAdminCheck';
		return this.http.put(url, {email: email}, {responseType: 'text'})
			.pipe(
				map((result: string) => {
					return result === 'exists';
				}),
			);
	}

	public getShopifyCustomer(email: string): Observable<string> {
		var url = this.functionUrl + '/callShopifyCustomer';
		return this.http.put(url, {email: email}, {responseType: 'text'});
	}

	public checkCode(code: string) {
		return this.http.put(`${this.functionUrl}/checkCode`, {code: code}, {responseType: 'text'});
	}

	public getRefreshOrders(customerId: number): Observable<boolean> {
		var url = this.functionUrl + '/callShopifyOrders';
		return this.http.put(url, {customerId: customerId}, {responseType: 'text'})
			.pipe(
				map((result: any) => {
					return (result === 'ok');
				}),
			);
	}
}
