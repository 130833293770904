import { Component, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { StateService } from "../services/state.service";
import { StudentService } from "../services/student.service";

@Component({
	selector: "menu-bar",
	templateUrl: "./menu-bar.component.html",
	styleUrls: ["./menu-bar.component.scss"],
})
export class MenuBarComponent {
	imageUrl: string;
	mobileImageUrl = "assets/img/Accompany/small-logo.png";
	desktopImageUrl = "assets/img/Accompany/Logo.png";

	constructor(
		public authService: AuthService,
		public router: Router,
		public stateService: StateService,
		public studentService: StudentService
	) {
		this.setImageUrl(window.innerWidth);
	}

	@HostListener("window:resize", ["$event"])
	onResize(event: Event) {
		this.setImageUrl((event.target as Window).innerWidth);
	}

	private setImageUrl(windowWidth: number) {
		this.imageUrl =
			windowWidth < 768 ? this.mobileImageUrl : this.desktopImageUrl;
	}

	public signOut(): void {
		this.authService.signOut();
		this.router.navigate([""]);
	}
}
