import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChange,
} from "@angular/core";
import { EventEmitter as EventEmitterPlayer } from "event-emitter";
import { DecompressedSong } from "../models/DecompressedSong";
import { RehearsalMark } from "../models/RehearsalMark";
import { TMidiFile } from "../models/TMidiFile";

@Component({
	selector: "app-player-measure-markers",
	templateUrl: "./player-measure-markers.component.html",
	styleUrls: ["./player-measure-markers.component.scss"],
})
export class PlayerMeasureMarkersComponent implements OnInit {
	@Input() selectedMarker: RehearsalMark;
	@Input() currentMarker: RehearsalMark;
	@Input() song: DecompressedSong;
	@Input() playerEvents: EventEmitterPlayer;
	@Output() markerEmitter = new EventEmitter<RehearsalMark>();
	public markers: RehearsalMark[] = [];

	constructor() {}

	ngOnInit(): void {
		if (
			this.song &&
			this.song.midi &&
			this.song.midi.tracks &&
			this.song.midi.tracks[0]
		) {
			this.getRehearsalMarks(this.song.midi);
		} else {
			this.markers = [];
		}

		if (this.playerEvents) {
			this.playerEvents.on("timeupdate", (seconds: number) => {
				let tmpCurrent: RehearsalMark | null = this.markers[0] || null;
				for (let marker of this.markers) {
					if (marker.elapsedSeconds < seconds) {
						tmpCurrent = marker;
					} else {
						this.currentMarker = tmpCurrent;
						return;
					}
				}
				this.currentMarker = null;
				return;
			});
		}
	}

	ngOnChanges(change: SimpleChange) {
		if (change["song"]) {
			if (this.song) {
				if (
					this.song.midi &&
					this.song.midi.tracks &&
					this.song.midi.tracks[0]
				) {
					this.getRehearsalMarks(this.song.midi);
				} else {
					this.markers = [];
					console.error("No midi provided");
				}
			} else {
				this.markers = [];
			}
		}
	}

	getRehearsalMarks(midi: TMidiFile): void {
		if (!midi.division || !midi.tracks[0]) {
			throw "Invalid midi given";
		}
		const messages: any = midi.tracks[0];
		const ticksPerQuarter = midi.division;

		let microsecondsPerTick: number | null = null;
		let accumulatedTicks: number = 0;
		let accumulatedMicroseconds: number = 0;

		for (let message of messages) {
			accumulatedTicks += message.delta;

			if (message.setTempo) {
				microsecondsPerTick =
					message.setTempo.microsecondsPerQuarter / ticksPerQuarter;
			} else if (!microsecondsPerTick) {
				if (accumulatedTicks > 0)
					throw "Accumulated ticks was incremented before microseconds per tick was set. Corrupted midi file";
				continue; // Have to have time before we can start going
			}

			accumulatedMicroseconds += message.delta * microsecondsPerTick;

			let name: string | null = null;

			if (message.marker) {
				// They only want markers to be displayed
				name = message.marker;
				const next: RehearsalMark = {
					name: name,
					elapsedSeconds: accumulatedMicroseconds / 10 ** 6,
					timeStamp: this.msToTimeStamp(accumulatedMicroseconds),
				};
				this.markers.push(next);
			}
		}
	}

	msToTimeStamp(ms: number): string {
		return new Date(ms / 1000).toISOString().substr(11, 12);
	}
}
