import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { FirestoreService } from './firestore.service';
import * as zip from '@zip.js/zip.js';
import { DecompressedSong, Track } from '../models/DecompressedSong';
import { parseArrayBuffer } from 'midi-json-parser';
import { TMidiFile } from '../models/TMidiFile';

zip.configure({ useWebWorkers: false });


@Injectable({
  providedIn: 'root'
})
export class SongService {

  constructor(
    private firestoreService: FirestoreService,
  ) { }

  getSong(sku: string): Observable<any> {
    return this.getSongFromDb(sku);
  }

  getSongFromDb(sku: string): Observable<DecompressedSong> {
    return this.firestoreService.getMusicFiles(sku).pipe(
      switchMap((blob: File): Observable<DecompressedSong> => {
        return from(this.getEntriesAsync(blob)).pipe(
          switchMap((entries: zip.Entry[]) => {
            const meta = entries.find(entry => entry.filename === 'meta.js');
            if (!meta)
              throw 'meta.js file was not included';
            return from(this.getMetaFileAsync(meta)).pipe(
              switchMap((blueprint: any) => {
                if (!blueprint || !blueprint.track || !blueprint.midiFile)
                  throw 'Error parsing the meta.js file';

                const trackObs: Observable<Track | TMidiFile>[] = [];
                let midiFile: TMidiFile | null = null;

                for (let track of blueprint.track) {
                  const entry = entries.find(
                    entry => entry.filename === track.filename
                  );
                  if (!entry)
                    throw 'Not all required tracks were included in the zip';
                  trackObs.push(from(this.getTrackAsync(entry, track.title)));
                }
                const entry = entries.find(
                  entry => entry.filename === blueprint.midiFile
                );
                if (!entry)
                  throw 'Required midi was not included in the zip';
                trackObs.push(from(this.getMidiFileAsync(entry)));

                return forkJoin(trackObs).pipe(
                  map((tracks: (Track | TMidiFile)[]) => {
                    tracks = tracks.filter(track => {
                      if (track instanceof Track) {
                        return true;
                      } else {
                        midiFile = track;
                        return false;
                      }
                    });
                    let title: string = blueprint.title;
                    title += blueprint.description ? ` (Arr. ${blueprint.description})` : '';
                    return DecompressedSong.fromObj({
                      sku: sku,
                      title: title,
                      tracks: tracks,
                      midi: midiFile || null,
                    });
                  }),
                );
              }),
            );
          }),
        );
      }),
    );
  }

  async getEntriesAsync(blob: File): Promise<zip.Entry[]> {
    const zipReader = new zip.ZipReader(new zip.BlobReader(blob));
    const entries = await zipReader.getEntries();
    await zipReader.close();
    return entries;
  }

  async getTrackAsync(entry: zip.Entry, name?: string): Promise<Track> {
    const data = await entry.getData(new zip.BlobWriter('audio/mp3'));
    const file = new File([data], entry.filename, {type: 'audio/mp3'});
    return Track.fromObj({
      name: name ? name : entry.filename.split('_')[0].split('.')[0],
      src: URL.createObjectURL(file),
    });
  }

  async getMetaFileAsync(entry: zip.Entry): Promise<any> {
    const data = await entry.getData(new zip.BlobWriter('text/javascript'));
    const arrBuff = await data.arrayBuffer();
    const decoder = new TextDecoder("utf-8");
    return JSON.parse(decoder.decode(arrBuff)).song;
  }

  async getMidiFileAsync(entry: zip.Entry): Promise<TMidiFile> {
    const data = await entry.getData(new zip.BlobWriter('audio/midi'));
    const file = new File([data], entry.filename, {type: 'audio/midi'});
    const arrBuffer = await file.arrayBuffer();
    const midiJson: TMidiFile = await parseArrayBuffer(arrBuffer);
    return midiJson;
  }
}
