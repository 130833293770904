import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '../models/Breadcrumb';
import { StateService } from '../services/state.service';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs!: Breadcrumb[];

  constructor(private stateService: StateService) { }

  ngOnInit() {
    this.stateService.breadcrumbs.subscribe((breadcrumbs: Breadcrumb[]) => {
      this.breadcrumbs = breadcrumbs;
    });
  }

}
