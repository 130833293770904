export class Musician {
	name: string;
	email: string;
	instrument: string[];

	constructor(name: string, email: string, instrument: string[]) {
		this.name = name;
		this.email = email;
		this.instrument = instrument;
	}

	static fromDb(data: any): Musician | null {
		try { 
			return new this(
				data.name || '',
				data.email || '',
				data.instrument || [],
			);
		} catch {
			console.error(`Could not create Musician from ${data.email}`);
			return null;
		}
	}
}
