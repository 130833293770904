import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Group } from '../models/Group';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FirestoreService } from '../services/firestore.service';
import { Customer } from '../models/Customer';
import { StateService } from '../services/state.service';
import { Router } from '@angular/router';


const genres: string[] = [
	'Choir',
	'Jazz',
	'Musical Review',
	'Orchestra',
	'Other',
]

@Component({
  selector: 'groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})


export class GroupsComponent {

	@Input() customer!: Customer;
	@Input('group') group: Group = null;
	@Input('groups') groups: Group[];
	@Output() editGroupEmitter: EventEmitter<Group> = new EventEmitter<Group>();

	public title: string = '1. Select a Group';
	public displayedColumns: string[] = ['name', 'products', 'musicians', 'action'];
	public genres = genres;
	public formGroup: UntypedFormGroup;
	public name = new UntypedFormControl('', [Validators.required,]);
	public groupGenres = new UntypedFormControl();
	public new: boolean = false;
	public error?: string;

	constructor(
		builder: UntypedFormBuilder,
		private firestoreService: FirestoreService,
		private stateService: StateService,
		private router: Router,
	) {
		this.title = this.group == null? '1. Select a Group' : `1. Editing Group: ${this.group.name}`;
		this.formGroup = builder.group({
			name: this.name,
			groupGenres: this.groupGenres,
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.group && !changes.group.firstChange) {
			if (this.group.id) {
				this.editGroup(this.group);
			} 
		}
	}

	public editGroup(group: Group) {
		this.title = `1. Editing Group: ${group.name}`;
		this.name.setValue(group.name);
		this.groupGenres.setValue(group.genres);
		this.new = false;

		this.editGroupEmitter.emit(group);
	}

	public addGroup() {
		let group = new Group(null, '', [], [], []);
		this.title = '1. Adding Group:';
		this.stateService.breadcrumbs.next([{
			name: 'Home',
			route: '/dashboard'
		},{
			name: 'Create Group',
			route: '/manage'
		}])
		this.new = true;

		this.editGroupEmitter.emit(group);
	}

	public deleteGroup(group: Group) {
		if(this.firestoreService.deleteGroup(group.id, group.name)) {
			this.groups.splice(this.groups.indexOf(group), 1);
			this.groups = [].concat(this.groups);
			this.router.navigate(['/manage'])
		}
	}

	public cancelNewGroup() {
		this.group = null;
		this.title = '1. Select Group';
		this.new = false;
	}

	saveFormGroup() {
		if (!this.formGroup.valid) {
			window.alert('ERROR: The create group form is not valid');
			return;
		}
		this.group.name = this.formGroup.get('name')?.value;
		this.group.genres = this.formGroup.get('groupGenres')?.value;
		this.saveGroup();
	}

	public saveGroup() {
		this.firestoreService.saveGroup(this.group, this.customer.id)
			.subscribe((group: Group) => {
				this.group = group;
				this.editGroup(this.group);
			},(error: any) => {
				window.alert('Error saving the group. Please try again');
				console.error(error);
			});
	}
}
