import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-song-selector-item',
  templateUrl: './song-selector-item.component.html',
  styleUrls: ['./song-selector-item.component.scss']
})
export class SongSelectorItemComponent implements OnInit {
  @Input() song!: {sku: string, title: string};
  @Input() selected!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
