/** either pass in router.url (angular's Router class) or window.location.pathname */
export function parseURL(url): any {
	let original:string = url;
	let split:string[] = url.split('?');
	let route:string = split[0];
	let params:string = split.length > 1
		? split[1]
		: '';
	let _params:object = {};
	for (let param of params.split('&')) {
		let keyValue = param.split('=');
		let key = keyValue[0];
		let value = keyValue[1];
		if (key) {
			_params[key] = value;
		}
	}

	let segments = route.split('/');
	// if passing in window.location.pathname the first element will be '', let's remove it
	if (segments.length && segments[0] == '') {
		// remove first element
		segments.splice(0, 1);
	}

	// convert our route into key/value pairs
	// example: project-manager/designer/7 will create keyValues = {designer:7}
	let keyValues = {};
	for (let i=0; i<segments.length; i++) {
		if (i < segments.length-1) {
			if (!isNaN(Number(segments[i+1]) || NaN)) {
				let key = segments[i];
				let value = segments[i+1];
				keyValues[key] = value;
			}
		}
	}

	return {
		keyValues,
		original,
		params: _params,
		route,
		segments,
	};
	}
