import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Component({
  selector: 'confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: [
    '../login/login.component.scss',
    './confirm-email.component.scss',
  ],
})
export class ConfirmEmailComponent implements OnInit {
  public submitted: boolean = false;
  private oobCode?: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      if (params.mode !== 'verifyEmail') {
        this.router.navigate(['/']);
        throw 'Mode is not verifyEmail. You should not be here';
      }
      this.auth.checkActionCode(params.oobCode).subscribe((valid: boolean) => {
        if (!valid) {
          this.router.navigate(['/']);
          // TODO:0 Figure out how to resend the email
          window.alert('The link you used has expired.');
        }
        this.oobCode = params.oobCode;

        this.auth.verifyEmail(this.oobCode).subscribe((success: boolean) => {
          if (success) {
            window.alert('Success! Your email has been validated');
          } else {
            // TODO:0 Figure out how to resend the email
            window.alert('Failed to verify your email.');
          }
          this.router.navigate(['/']);
        });
      });
    });
  }

  submit() {
    this.submitted = true;
    this.auth.verifyEmail(this.oobCode)
      .subscribe((success: boolean) => {
        if (success) {
          window.alert('Success! Your password has been reset');
        } else {
          window.alert('Failed to reset your password. Please request another password reset link');
        }
        this.router.navigate(['/']);
      });
  }

}
