import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlayerSongGroup } from '../models/PlayerSongGroup';
import { PlayerSongRef } from '../models/PlayerSongRef';
import { Student } from '../models/Student';
import { FirestoreService } from '../services/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  public student: Student | null = null;

  constructor(
    private firestoreService: FirestoreService,
    private router: Router,
  ) { }

  getStudent(): Student | null {
    if (!this.student) {
      return this.setStudent(JSON.parse(localStorage.getItem('accompany-student')));
    }
    return this.student;
  }

  setStudent(student: Student | null): Student | null {
    this.student = student;
    localStorage.setItem('accompany-student', JSON.stringify(student));
    return this.student;
  }

  destroyStudent() {
    return this.setStudent(null) ? false : true;
  }

  fromAccessRecord(email: string, accessRecord: any[]): void {
    const groupObs: Observable<PlayerSongGroup>[] = [];
    for (let record of accessRecord) {
      groupObs.push(forkJoin([
        this.firestoreService.getGroup(record.groupId),
        this.firestoreService.getSongRecords(record.access.map(rec => rec.sku))
      ]).pipe(
        map(([group, songs]): PlayerSongGroup | null => {
          if (group === null) {
            console.error(`The assigned group with id '${record.groupId}' does not exist. This access record should have been removed upon deletion but wasn't`);
            return null;
          }
          return {name: group.name, songs: songs} as PlayerSongGroup;
        }
      )));
    }

    forkJoin(groupObs).subscribe((playerSongGroups: (PlayerSongGroup | null)[]) => {
      playerSongGroups = playerSongGroups.filter(song => !!song);
      this.setStudent(new Student(email, playerSongGroups));
      this.router.navigate(['/player']);
    });
  }

  fromSongRef(songRef: PlayerSongRef): void {
    this.setStudent(new Student(
      'Guest',
      [{
        name: 'Promo Code',
        songs: [songRef]
      } as PlayerSongGroup],
    ));
    this.router.navigate(['/player']);
  }

  signOut() {
    this.student = undefined;
    localStorage.setItem('accompany-student', null);
    this.router.navigate(['']);
  }
}
