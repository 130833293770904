<div id="main-container">
	<nav>
		<a class="navbar-brand" href="/">
			<img src="{{ imageUrl }}">
		</a>
		<div
			*ngIf="studentService.getStudent()"
				class="right-nav-flex"
		>
			<div>
				<p class="user-text">Welcome {{studentService.student.email}}</p>
			</div>
			<div class="nav-links-container">
				<a></a>
				<a (click)="signOut()">SIGN OUT</a>
			</div>
		</div>
		<div
				class="right-nav-flex"
				*ngIf="stateService.getUser()"
		>
			<div>
				<p></p>
				<p
						*ngIf="stateService.getUser().is_admin"
						class="user-text"
				>Admin Customer Selected <b>{{stateService.customer.name}}</b></p>
			</div>
			<div class="nav-links-container">
				<a
						href="https://app.milanote.com/1I3pIq1lw7gGdC"
						target="_blank"
				>MENTORED BY THE MASTERS</a>
				<a
						routerLink="/manage"
						routerLinkActive="active"
				>MANAGE GROUPS & MUSIC</a>
				<a (click)="signOut()">SIGN OUT</a>
			</div>
		</div>
		<div 
			class="right-nav-flex"
			*ngIf="this.router.url == '/'"
		>
			<div class="menu-login-mode">
				<p (click)="stateService.updateModeLogin('Director')" *ngIf="stateService.modeLogin == 'Student'" class="user-text flex-item bottom">Director Login</p>
				<p (click)="stateService.updateModeLogin('Student')" *ngIf="stateService.modeLogin == 'Director'" class="user-text flex-item bottom">Student Login</p>
			</div>
		</div>
	</nav>
</div>
