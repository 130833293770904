import { Component, Input } from '@angular/core';
import { AccessRecord } from '../models/AccessRecord';
import { Group } from '../models/Group';
import { FirestoreService } from '../services/firestore.service';


@Component({
  selector: 'musician-access',
  templateUrl: './musician-access.component.html',
  styleUrls: ['./musician-access.component.scss']
})
export class MusicianAccessComponent {
	@Input() group!: Group;

	public musicianAccessRecords: AccessRecord[] = [];
	public displayedColumns: string[] = ['name', 'sku'];

  constructor(
		private firestoreService: FirestoreService, 
	) { }

	public loadAccessRecords() {
		this.musicianAccessRecords = [];
		for (let i = 0; i < this.group.musicians.length; i++) {
			this.firestoreService.getMusicianAccess(this.group.musicians[i].email)
				.subscribe((accessRecords: {access: any[]; groupId: string}[]) => {
					const access: AccessRecord[] = [];
					for (let j = 0; j < accessRecords.length; j++) {
						if (accessRecords[j].groupId === this.group.id) {
							access.push({
								email: this.group.musicians[i].email,
								skus: accessRecords[j].access.map(obj => obj.sku),
							});
						}
					}
					this.musicianAccessRecords = [...this.musicianAccessRecords, ...access];
				});
		}
	}
}
