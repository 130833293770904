<div id="player-container">
  <app-player-song-selector
      id="song-selector"
      [student]="student"
      [selectedSku]="selectedSku"
      (changeSongEmitter)="changeSong($event)"
  ></app-player-song-selector>
  <audio-player
      id="audio-player"
      *ngIf="song"
      [song]="song"
      [playerEvents]="playerEvents"
  ></audio-player>
  <div
      *ngIf="!loading && !song"
      id="select-a-song"
  >
    <h1>Please select a song</h1>
  </div>
  <div
      *ngIf="loading"
      id="loading"
  >
    <h1>Please wait while we load your music...</h1>
    <mat-spinner></mat-spinner>
  </div>
  <app-player-measure-markers
      id="measure-markers"
      [selectedMarker]="selectedMarker"
      [song]="song"
      [playerEvents]="playerEvents"
      (markerEmitter)="changeMarker($event)"
  ></app-player-measure-markers>
</div>
