import { PlayerSongGroup } from "./PlayerSongGroup";

export class Student {
  email: string;
  groups: PlayerSongGroup[];

  public constructor(
    email: string,
    groups: PlayerSongGroup[],
  ) {
    this.email = email;
    this.groups = groups;
  }

  static fromObj(data: any): Student | null {
    try {
      return new this(
        data.email,
        data.groups,
      );
    } catch {
      console.error('Error creating a new student from object');
      return null;
    }
  }
}
