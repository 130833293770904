import { Product, ProductVariant } from "./Product";

export class ProductPack extends Product {
  productAssigned: number;  // Not a typo, this is what the db calls it... smh
  productItems: number;
  groupProductItems: PackProduct[];

  public constructor(
			id: string,
			title: string,
			sku: string,
			genre: string,
			type: string,
			assigned: number,
			quantity: number,
      productAssigned: number,
      productItems: number,
      groupProductItems: PackProduct[],
      expires: number,
      groupIds: string[],
      variants: ProductVariant[],
      available: number,
  ) {
    super(id, title, sku, genre, type, assigned, quantity, expires, groupIds, variants, available);
    this.groupProductItems = groupProductItems || [];
    this.productItems = productItems;
    this.productAssigned = productAssigned || groupProductItems.length;
  }

  static fromObject(data: Record<string, any>): ProductPack | null {
    try {
      if (!data.variants)
        data.variants = [];

      return new this(
        data.id,
        data.title,
        data.sku,
        data.genre,
        data.type,
        Number(data.assigned),
        Number(data.available) + Number(data.assigned),
        data.productAssigned,
        Number(data.productItems),
        data.groupProductItems.map((prod: any) => {
          return {
            expiresOn: {seconds: prod.expiresOn.seconds},
            productItemId: prod.productItemId,
            sku: prod.sku,
            title: prod.title,
            groupIds: prod.groupIds,
          } as PackProduct;
        }),
        data.expiresOn.seconds * 1000,  // Make into milliseconds
        data.groupIds || [],
				data.variants.map(v => {
					return {
						available: v.available || 0,
						genre: v.genre || null,
						performance: v.performance || null,
						sku: v.sku || null,
						title: v.title || null,
						type: v.type || null,
					};
				}),
        Number(data.available),
      )
    } catch (error) {
      console.error(error)
      return null;
    }
  }
}

export class PackProduct {
  expiresOn: {seconds: number};
  productItemId: string;
  sku: string;
  title: string;
  groupIds: string[];

  static fromProduct(product: Product): PackProduct {
    const packProduct = new this();
    packProduct.expiresOn = {seconds: product.expires * .001};
    packProduct.productItemId = product.id;
    packProduct.sku = product.sku;
    packProduct.title = product.title;
    packProduct.groupIds = product.groupIds;
    return packProduct;
  }
}
