import { Component, Input } from '@angular/core';
import { Group } from '../models/Group';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FirestoreService } from '../services/firestore.service';
import { Customer } from '../models/Customer';
import { Musician } from '../models/Musician';

const instrument: string[] = [
	'Saxophone',
	'Trumpet',
	'Trombone',
	'Rhythm Section',
	'Optional/Alt Parts',
	'Orchestra',
	'Choir',
]

@Component({
  selector: 'group-members',
  templateUrl: './musicians.component.html',
  styleUrls: ['./musicians.component.scss']
})
export class GroupMusiciansComponent {

	@Input('group') group: Group = null;
	@Input() customer!: Customer;
	public instrument_options = instrument;
	public title: string = '3. Add Group Members';
	public displayedColumns: string[] = ['musician', 'email', 'instrument', 'action'];
	public formGroup: UntypedFormGroup;
	public name = new UntypedFormControl();
	public email = new UntypedFormControl();
	public instrument = new UntypedFormControl();
	public new: boolean = false;
	public error?: string;


  constructor(
		builder: UntypedFormBuilder,
		public firestoreService: FirestoreService,
	) {
		this.formGroup = builder.group({
			name: this.name,
			email: this.email,
			instrument: this.instrument,
		});
	}

	public showAddMusician() {
		this.new = true;
	}

	public cancelNewMusician() {
		this.new = false;
	}

	public saveMusician() {
		if (!this.formGroup.valid) {
			this.error = "ERROR: Musician form is not valid";
			window.alert(this.error);
			return;
		}
		const musician = new Musician(
			this.formGroup.get('name')?.value,
			this.formGroup.get('email')?.value.toLowerCase().trim(),
			this.formGroup.get('instrument')?.value || [],
		);
		if (!musician) {
			window.alert('Could not create a musician from the given data');
			throw 'Could not create a musician from the given data';
		}

		this.group.musicians.push(musician);
		this.firestoreService.saveGroup(this.group, this.customer.id)
			.subscribe((group: Group) => {
				this.group = group;
			});
		this.new = false;
	}

	public removeMusician(musician: Musician) {
		if (!musician) {
			window.alert('ERROR: No musician given in call to remove a musician');
			return;
		}
		const idx = this.group.musicians.indexOf(musician);
		this.group.musicians.splice(idx, 1);

		this.firestoreService.deleteMusicianAccess(musician.email, this.group.id).subscribe();
		this.firestoreService.saveGroup(this.group, this.customer.id)
			.subscribe((group: Group) => {
				this.group = group;
			});
	}

}
