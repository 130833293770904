// For upgrade notes see https://github.com/angular/angularfire/blob/master/docs/version-7-upgrade.md
import { AngularFireModule } from '@angular/fire/compat'; // TODO: Deprecated. See notes on upgrading to v7.*
import { AngularFireStorageModule } from '@angular/fire/compat/storage'; // TODO: Deprecated. See notes on upgrading to v7.*
import { AngularFireAuthModule } from '@angular/fire/compat/auth'; // TODO: Deprecated. See notes on upgrading to v7.*
import { AngularFirestoreModule } from '@angular/fire/compat/firestore'; // TODO: Deprecated. See notes on upgrading to v7.*

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AuthGuard } from './AuthGuard';
import { ContentContainerComponent } from './content-container/content-container.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { environment } from 'src/environments/environment';
import { GroupsComponent } from './groups/groups.component';
import { GroupMusiciansComponent } from './musicians/musicians.component';
import { LoginComponent } from './login/login.component';
import { ManageComponent } from './manage/manage.component';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { MusicianAccessComponent } from './musician-access/musician-access.component';
import { SongsComponent } from './songs/songs.component';
import { StateService } from './services/state.service';
import { ProductsComponent } from './products/products.component';
import { ProductListComponent } from './product-list/product-list.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { PlayerSongSelectorComponent } from './player-song-selector/player-song-selector.component';
import { PlayerComponent } from './player/player.component';
import { PlayerMeasureMarkersComponent } from './player-measure-markers/player-measure-markers.component';
import { SongSelectorItemComponent } from './song-selector-item/song-selector-item.component';
import { MeasureMarkerItemComponent } from './measure-marker-item/measure-marker-item.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SongListComponent } from './song-list/song-list.component';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

@NgModule({
	declarations: [
		AppComponent,
		BreadcrumbComponent,
		ContentContainerComponent,
		ConfirmEmailComponent,
		DashboardComponent,
		GroupsComponent,
		GroupMusiciansComponent,
		LoginComponent,
		ManageComponent,
		MenuBarComponent,
		MusicianAccessComponent,
		ProductsComponent,
		ProductListComponent,
		AudioPlayerComponent,
		SongsComponent,
		PlayerSongSelectorComponent,
		PlayerComponent,
		PlayerMeasureMarkersComponent,
		SongSelectorItemComponent,
		MeasureMarkerItemComponent,
		ResetPasswordComponent,
		SongListComponent,
	],
	imports: [
		MaterialModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireStorageModule,
		AngularFireAuthModule,
		AngularFirestoreModule,
		AppRoutingModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		ReactiveFormsModule,
	],
	providers: [
		AuthGuard,
		StateService,
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
