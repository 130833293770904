import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Student } from '../models/Student';

@Component({
  selector: 'app-player-song-selector',
  templateUrl: './player-song-selector.component.html',
  styleUrls: ['./player-song-selector.component.scss']
})
export class PlayerSongSelectorComponent implements OnInit {
  @Input() student!: Student;
  @Input() selectedSku!: string;
  @Output() changeSongEmitter = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void { }

}
