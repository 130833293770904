<mat-card class='mat-elevation-z0'>
	<mat-card-header>
		<mat-card-title>
			Musician Access Files
		</mat-card-title>
		<button
				mat-flat-button
				style="color: #FFFFFF; background-color: #ee3041;"
				(click)="loadAccessRecords()"
		>
			<mat-icon>file_download</mat-icon>Load Access Records
		</button>
	</mat-card-header>
	<div class='card-content'>
		<mat-table [dataSource]='musicianAccessRecords' class='mat-elevation-z0'>
			<ng-container matColumnDef='name'>
				<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
					<mat-cell *matCellDef='let accessRecord'>
						{{accessRecord.email}}
					</mat-cell>
			</ng-container>

			<ng-container matColumnDef='sku'>
				<mat-header-cell *matHeaderCellDef>SKU</mat-header-cell>
					<mat-cell *matCellDef='let accessRecord'>
						<div class="access-sku">
							<ng-container *ngFor='let sku of accessRecord.skus'>
								{{sku}}
								<br>
							</ng-container>
						</div>
					</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef='displayedColumns'></mat-header-row>
			<mat-row *matRowDef='let row; columns: displayedColumns;'></mat-row>
		</mat-table>
	</div>
</mat-card>
